import styles from "../index.module.css"
import { Box, Button, Checkbox, CircularProgress, createStyles, FormControl, FormControlLabel, FormGroup, Grid, Theme, Typography, withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { IBookingCreateInput, ICustomerInfoInput } from '../../../../../reducers/bookings/types'
import { IBusinessCustomer, ICustomer } from '../../../../../reducers/auth/types'
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client"
import { CONSUMER_VERIFY_CUSTOMER_LICENCE_DVLA } from "../../../../../graphql/customers/consumerVerifyCustomerLicenceDVLA"
import { SOCKET_EVENTS } from "../../../utils"
import { socket } from "../../../../../utils/socket"
import { IAppState } from "../../../../../store"
import { useSelector } from "react-redux"
import { GET_CONSUMER_CUSTOMER } from "../../../../../graphql/customers/getConsumerCustomer"
import { useSnackBar } from "../../../../SnackBarContext/SnackBarContext"
import { DATE_TYPE, formatGraphQLErrorMessage } from "../../../../utils"
import { SnackBarVariant } from "../../../../SnackbarWrapper/SnackbarWrapper"
import { GET_BUSINESS_CREDIT_SCORE } from "../../../../../graphql/customers/getConsumerBusinessCreditScroeMutation"
import { GET_CREDIT_SCORE } from "../../../../../graphql/customers/getCreditScoreMutation"
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex"
import { TextField } from "formik-material-ui"

interface IProps {
  bookingData: IBookingCreateInput;
  onSubmit: (data: IBookingCreateInput) => void
  customerInfo?: ICustomerInfoInput
}

export interface IDvlaCheckValues {
  licenseNumber: string;
  insuranceNumber: string;
  postCode: string;
}


export enum DvlaVerificationStatus {
  PENDING = "pending",
  SUCCEEDED = "succeeded",
  FAILED = "failed"
}

const dvlaCheckInitialValues: IDvlaCheckValues = {
  licenseNumber: "",
  insuranceNumber: "",
  postCode: ""
}

const Verification: React.FC<IProps> = (props) => {

  const snackbar = useSnackBar();

  const { customerInfo } = props;

  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const { country } = website.organisation.address;

  const [values, setValues] = useState(dvlaCheckInitialValues)
  const [agreedDvla, setAgreedDvla] = useState<boolean>(false);
  const [dvlaVerificationStatus, setDvlaVerificationStatus] = useState<string>(
    ""
  );
  const [customer, setCustomer] = useState<ICustomer>()
  const [businessCustomer, setBusinessCustomer] = useState<IBusinessCustomer>()
  const [creditScore, setCreditScore] = useState<number>(0);
  const [lastChecked, setLastChecked] = useState<string>("");

  const [getConsumerCustomer] = useLazyQuery(GET_CONSUMER_CUSTOMER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data && data.customer && data.customer.license) {
        setDvlaVerificationStatus(data.customer.license.dvlaVerificationStatus)
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [consumerVerifyCustomerLicenceDvla] = useLazyQuery(
    CONSUMER_VERIFY_CUSTOMER_LICENCE_DVLA,
    {
      fetchPolicy: "no-cache"
    }
  );

  const verifyLicenceWithDvla = (data: IDvlaCheckValues) => {
    consumerVerifyCustomerLicenceDvla({
      variables: {
        ...data,
        customerId: customer?.id
      }
    });
    setDvlaVerificationStatus(DvlaVerificationStatus.PENDING);
  };

  const [
    getCreditScoreForBusinessCustomer,
    { loading: businessScoreLoading }
  ] = useMutation(GET_BUSINESS_CREDIT_SCORE, {
    onCompleted: (data) => {
      if (!data.getConsumerBusinessCreditScore) {
        snackbar({
          message: "No valid credit reading found for this company.",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  useEffect(() => {
    if (user) {
      if (user.customer) {
        setCustomer(user.customer)
      } else {
        setBusinessCustomer(user?.businesscustomers)
      }
    }

  }, [user])
  useEffect(() => {
    if (customer) {
      setValues({
        ...values,
        licenseNumber: customer.license.licenseNumber || "",
        insuranceNumber: customer.nationalInsuranceNumber || "",
        postCode: customer.location?.zipcode || ""
      })
      if(customer.creditSafeData) {
        setCreditScore(customer.creditSafeData.creditScore)
        setLastChecked(customer.creditSafeData.lastChecked)
      }
    }
  }, [customer])

  useEffect(() => {
    if (user && user.customer) {
      socket.emit(SOCKET_EVENTS.REQUEST_VIEW_CUSTOMER, { customerId: user.customer.id });
    }
  }, [user]);

  useEffect(() => {
    socket.auth = {
      userId: user?.id
    };
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    socket.on(SOCKET_EVENTS.GET_UPDATED_CUSTOMER, (data) => {
      getConsumerCustomer({
        variables: {
          customerId: data.customerId
        }
      })
    })

    return () => {
      socket.off(SOCKET_EVENTS.GET_UPDATED_CUSTOMER);
    }
  }, [])

  const getBusinessCreditScore = () => {
    getCreditScoreForBusinessCustomer({
      variables: {
        businessCustomerId: businessCustomer?.id
      }
    });
  };

  const [getCreditScoreForCustomer, { loading: scoreLoading }] = useMutation(
    GET_CREDIT_SCORE,
    {
      onCompleted: (data) => {
        if (!data.getConsumerCreditScore.creditSafeData.creditScore) {
          snackbar({
            message:
              "No valid credit score found. This may be due to over exceeded credit checks limit",
            variant: SnackBarVariant.ERROR
          });
        } else {
          setCreditScore(data.getConsumerCreditScore.creditSafeData.creditScore);
        }
        setLastChecked(data.getConsumerCreditScore.creditSafeData.lastChecked);
      },
      onError: (error: ApolloError) =>
        snackbar({
          message:
            "No valid credit score found. This may be due to over exceeded credit checks limit",
          variant: SnackBarVariant.ERROR
        })
    }
  );

  const getCreditScore = () => {
    getCreditScoreForCustomer({
      variables: {
        customerId: customer?.id
      }
    });
  };

  const dvlaSchema = Yup.object().shape({
    licenseNumber: Yup.string().required("Driving licence number is required"),
    insuranceNumber: Yup.string().required("National insurance number is required"),
    postCode: Yup.string().required("Post code is required")
  });

  return (
    <Grid item xs={12} container spacing={2}>
      {
        customer && (
          <>
            <Grid item xs={6}>
              <Box className={styles.licence_check_container}>
                <Box className={styles.dvla_main_container}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12} container justify="center">
                      <Typography variant="h2">{"VERIFY YOUR LICENCE"}</Typography>
                    </Grid>
                    <Grid item xs={12} container justify="center">
                      <Typography variant="body1">
                        Please validate or enter following details to proceed for licence check. All fields are mandatory
                      </Typography>
                    </Grid>
                    <Formik
                      enableReinitialize
                      initialValues={values}
                      validationSchema={dvlaSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        verifyLicenceWithDvla(values)
                        setSubmitting(false)
                      }}
                    >
                      {(formikProps) => (
                        <Form style={{ flexGrow: 1, marginTop: 10, marginBottom: 20 }}>
                          <Grid container spacing={2} justify="center">
                            <Grid item xs={12} container justify="center">
                              <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                  <Field
                                    component={TextField}
                                    placeholder="Driving Licence Number"
                                    label="Driving Licence Number"
                                    value={formikProps.values.licenseNumber}
                                    name={"licenseNumber"}
                                    InputProps={{
                                      onChange: (event: any) => {
                                        setValues({
                                          ...formikProps.values,
                                          licenseNumber: event.target.value
                                        });
                                      }
                                    }}
                                    fullWidth
                                    required
                                  ></Field>
                                </FormControl>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} container justify="center" >
                              <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                  <Field
                                    component={TextField}
                                    placeholder="National Insurance Number"
                                    label="National Insurance Number"
                                    value={formikProps.values.insuranceNumber}
                                    InputProps={{
                                      onChange: (event: any) => {
                                        setValues({
                                          ...formikProps.values,
                                          insuranceNumber: event.target.value
                                        });
                                      }
                                    }}
                                    name={"insuranceNumber"}
                                    fullWidth
                                    required
                                  ></Field>
                                </FormControl>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} container justify="center" >
                              <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                  <Field
                                    component={TextField}
                                    placeholder="Post Code"
                                    label="Post Code"
                                    value={formikProps.values.postCode}
                                    InputProps={{
                                      onChange: (event: any) => {
                                        setValues({
                                          ...formikProps.values,
                                          postCode: event.target.value
                                        });
                                      }
                                    }}
                                    name={"postCode"}
                                    fullWidth
                                    required
                                  ></Field>
                                </FormControl>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} >
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={agreedDvla}
                                      onChange={(e: any) => {
                                        setAgreedDvla(e.target.checked)
                                      }}
                                      value={agreedDvla}
                                      color="primary"
                                      name={"agreed"}
                                    />
                                  }
                                  label={
                                    <Typography variant="body1">
                                      This agreement allows {website.organisation.name}  and our insurance partner to carry out checks
                                      on your driving licence and pass the data provided by you to insurers and other related third
                                      parties (Coastr) for the administration of your vehicle rental, insurance policy, etc.
                                      Do you accept and consent to the above?
                                    </Typography>
                                  }
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item xs={12} container justify="center">
                              <Grid item xs={6}>
                                <Button
                                  className={styles.next_step_btn}
                                  type="submit"
                                  fullWidth
                                  disabled={!values.licenseNumber || !values.insuranceNumber || !values.postCode || !agreedDvla}
                                >
                                  Verify Licence
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                  {
                    dvlaVerificationStatus === DvlaVerificationStatus.PENDING && (
                      <>
                        <Grid container xs={12} justify="center">
                          <Typography variant="h2">
                            Licence Checking In Progress
                          </Typography>
                        </Grid>
                        <Grid container xs={12} justify="center">
                          <Typography variant="body1">
                            It may take up to 2 minutes, please wait and do not refresh or close your browser
                          </Typography>
                        </Grid>
                        <Grid container xs={12} justify="center">
                          <CircularProgress />
                        </Grid>
                      </>
                    )
                  }
                  {
                    dvlaVerificationStatus === DvlaVerificationStatus.FAILED && (
                      <>
                        <Grid container xs={12} justify="center">
                          <Typography variant="h2">
                            Licence Checking Failed
                          </Typography>
                        </Grid>
                        <Grid container xs={12} justify="center">
                          <Typography variant="body1">
                            Please check if you have entered the correct details.
                          </Typography>
                        </Grid>
                        <Grid container xs={12} justify="center">
                          <Typography variant="body1">
                            If the issue still persists please try again later.
                          </Typography>
                        </Grid>
                      </>
                    )
                  }
                  {dvlaVerificationStatus === DvlaVerificationStatus.SUCCEEDED && <>
                    <Grid container xs={12} justify="center">
                      <Typography variant="h2">
                        Licence Checked Successfully
                      </Typography>
                    </Grid>
                    <Grid container xs={12} justify="center">
                      <Typography variant="body1">
                        Licence Summary added to customer record
                      </Typography>
                    </Grid>
                  </>
                  }
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box className={styles.licence_check_container}>
                <Box className={styles.dvla_main_container}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid container xs={12} spacing={1}>
                      { (creditScore || lastChecked) &&
                        <>
                          <Grid container item xs={12} style={{ paddingBottom: "20px" }}>
                            <Typography variant="h4">
                              Credit Score:{" "}
                              {creditScore === 0 ? (
                                <CircularProgress size={12} />
                              ) : (
                                creditScore
                              )}{" "}
                            </Typography>
                          </Grid>
                          <Grid container item xs={12} style={{ paddingBottom: "20px" }}>
                            <Typography variant="h4">
                              {" "}
                              Last Checked:{" "}
                              {lastChecked === "" ? (
                                <CircularProgress size={12} />
                              ) : (
                                getLocalizedDateFormat(
                                  country,
                                  lastChecked,
                                  DATE_TYPE.EXPANDED
                                )
                              )}
                            </Typography>
                          </Grid>
                        </>
                      }
                      <Grid item xs={12} container justify="center">
                        <Grid item xs={6}>
                          <Button
                            className={styles.next_step_btn}
                            onClick={() => {
                              getCreditScore();
                            }}
                            disabled={scoreLoading}
                            fullWidth
                          >
                            {scoreLoading && (
                              <CircularProgress size={14} style={{ color: "#ffffff" }} />
                            )}
                            {
                              creditScore || lastChecked ? "RE-CHECK CREDIT SCORE" : "CHECK CREDIT SCORE"
                            }
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </>
        )}
      <Grid item xs={12} container justify="flex-end">
        <Grid item>
          <Button
            className={styles.next_step_btn}
            onClick={() => {
              if (props.bookingData) {
                props.onSubmit(props.bookingData)
              }
            }}
            fullWidth
            type="submit"
          >
            Proceed to payment
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Verification