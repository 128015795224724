import {
  ITestDriveBookingState,
  TestDriveBookingSteps,
  TestDriveExperiences,
  TestDriveTypes,
} from "./types";

export const testDriveBookingInitialState: ITestDriveBookingState = {
  vehicleGroupId: "",
  branchId: "",
  vehicleId: "",
  selectedSlot: "",
  availableBranches: [],
  availableVehicleIds: [],
  extendedTestDriveDuration: 1,
  currentStep: TestDriveBookingSteps.WELCOME_PAGE,
  testDriveType: TestDriveTypes.STANDARD,
  stepperData: {
    dealership: "",
    model: "",
    slot: "",
    experience: "",
  },
  vehicleImage: "",
  pickupLocation: "",
  testDriveBookingDetails: {
    experience: TestDriveExperiences.CONSULTATION,
    type: TestDriveTypes.STANDARD,
  },
};
