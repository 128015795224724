import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  Fab,
  DialogContent,
  Button,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Skeleton } from "@material-ui/lab";
import { IVehicleAvailability } from ".";
import { DateTime as d } from "luxon";

interface IProps {
  vehicleAvailability: IVehicleAvailability[];
  dialogOpen: boolean;
  loading: boolean;
  handleDialogState: any;
  fetchAvailability: any;
  initialSelectedSlot: number;
  setSlot(selectedDate: string, selectedSlot: string, vehicleId: string): void;
}

const useStyles = makeStyles((theme) => ({
  selectedTimeSlot: {
    maxWidth: "100px",
    minWidth: "100px",
    "& .MuiChip-clickable:focus": {
      backgroundColor: "var(--brand-accent)",
    },
    backgroundColor: "var(--brand-accent)",
  },
  proceedButton: {
    marginBottom: theme.spacing(0.5),
    borderRadius: 0,
  },
  proceedIcon: {
    marginRight: theme.spacing(1),
  },
  listItem: {
    maxWidth: "100px",
  },
  timeSlotContainer: {
    maxHeight: "250px",
    overflow: "auto",
  },
  root: {
    maxWidth: 600,
    margin: "auto",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  timeSlot: {
    marginBottom: theme.spacing(0.5),
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  btnContainer: {
    minWidth: "120px",
  },
  calendarContainer: {
    minWidth: "310px",
  },
  slotsContainer: {
    minWidth: "400px",
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "760px",
      },
    },
  },
}));

export const CalendarTimeSlotSelector: React.FC<IProps> = ({
  dialogOpen,
  handleDialogState,
  fetchAvailability,
  loading,
  vehicleAvailability,
  initialSelectedSlot,
  setSlot,
}) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState<string>(
    d.now().toUTC().toISO()
  );
  const [selectedSlot, setSelectedSlot] = useState<number>(initialSelectedSlot);

  const handleDateChange = (date: MaterialUiPickersDate) => {
    setSelectedDate(date?.toISOString() || "");
    fetchAvailability(date?.toISOString() || "");
    setSelectedSlot(0);
  };

  const handleSelect = () => {
    if (selectedDate && selectedSlot) {
      const selectedVehicleAvailability = vehicleAvailability[selectedSlot];
      setSlot(
        selectedDate,
        selectedVehicleAvailability.vehicleId,
        selectedVehicleAvailability.timeSlot
      );
      handleDialogState();
    }
  };

  const handleSlotSelection = (slotId: number) => {
    setSelectedSlot(slotId);
  };

  return (
    <Dialog
      onClose={handleDialogState}
      aria-labelledby="slot-selector"
      open={dialogOpen}
      fullWidth
      className={classes.dialog}
    >
      <DialogTitle>
        <Typography variant={"h3"}>Select Date and Time Slot</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          xs={12}
          spacing={2}
          alignContent="center"
          justify="space-between"
          alignItems="center"
        >
          <Grid container item xs={5} className={classes.calendarContainer}>
            <DatePicker
              disableToolbar
              variant="static"
              disablePast
              value={selectedDate}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid container item xs={5} className={classes.slotsContainer}>
            {loading && (
              <Grid container item xs={12} spacing={1}>
                <Skeleton variant="rect" animation="wave" />
                <Grid container spacing={2}>
                  {new Array(10).fill(0).map((_) => (
                    <Grid item xs={12} sm={4}>
                      <Skeleton variant="rect" animation="wave" height={40} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
            {!loading && vehicleAvailability && (
              <Grid container item xs={12}>
                <Typography
                  variant="h3"
                  component="h3"
                  className={classes.title}
                >
                  Select a Time Slot
                </Typography>
                <Grid container xs={12} spacing={1}>
                  {vehicleAvailability.map((slot, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      key={index}
                      className={classes.btnContainer}
                    >
                      <Button
                        variant="outlined"
                        fullWidth
                        className={`${classes.timeSlot} ${
                          selectedSlot === index ? classes.selected : ""
                        }`}
                        onClick={() => handleSlotSelection(index)}
                      >
                        {slot.timeSlot}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
            {!loading && !vehicleAvailability.length && (
              <Grid container item xs={12} spacing={1}>
                <Typography variant="body1" style={{ marginTop: "16px" }}>
                  Vehicle Not Available for Selected Date.
                </Typography>
                <Typography variant="body1" style={{ marginTop: "16px" }}>
                  Please Select a Different Date.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container justify="center" style={{ marginTop: "20px" }}>
          <Fab
            aria-label="proceed-with-vehicle-selection"
            variant="extended"
            size="medium"
            className={classes.proceedButton}
            onClick={handleSelect}
            style={{
              backgroundColor:
                !selectedDate || !selectedSlot
                  ? "#e0e0e0"
                  : "var(--brand-accent)",
            }}
            disabled={!selectedDate || !selectedSlot}
          >
            Select Slot
          </Fab>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
