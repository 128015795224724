import { useLazyQuery, useMutation } from "@apollo/client";
import Container from "@material-ui/core/Container";
import { ApolloError } from "apollo-boost";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { CREATE_BOOKING } from "../../../graphql/bookings/createBookingMutation";
import {
  BOOKING_SOURCE,
  IAddonRequirementInput,
  IBooking,
  IBookingAddonRequirement,
  IBookingCreateInput,
  IBookingVehicleGroup,
  IBookingVehiclePriceGroup,
  ICustomerInfoInput,
  ILocationSurchargeInput,
  IOneWayRentalFee,
  BookingType,
  IVehicle,
} from "../../../reducers/bookings/types";
import { IVehiclePriceGroup } from "../../../reducers/bookings/types";
import { IAppState } from "../../../store";
import { useSnackBar } from "../../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../SnackbarWrapper/SnackbarWrapper";
import { bookingCategories, BookingFlowStage } from "./utils";
import { formatGraphQLErrorMessage, getAddonIcon, toCurrency } from "../../utils";
import BaseDetails from "./BaseDetails/BaseDetails";
import PersonalDetails from "./PersonalDetails/PersonalDetails";
import styles from './index.module.css'
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import SendIcon from '@material-ui/icons/Send';
import VehicleSelection from "./VehicleSelection/VehicleSelection";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { GET_AVAILABLE_VEHICLE_GROUPS, GET_EXTERNAL_AVAILABLE_VEHICLE_GROUPS } from '../../../graphql/bookings/getAvailableVehicleGroups';
import { GET_VEHICLE_GROUP_CRITERIA, GET_EXTERNAL_VEHICLE_GROUP_CRITERIA } from "../../../graphql/vehiclePriceGroup/getVehicleGroupCriteria";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import StepButton from "@material-ui/core/StepButton/StepButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { updateBooking as updateBookingAction } from '../../../actions/bookings/actions';
import PageModules from "../../PageModules";
import Slide from "@material-ui/core/Slide";
import { TopBar } from '../../TopBar/TopBar';
import LaunchIcon from '@material-ui/icons/Launch';
import Hidden from '@material-ui/core/Hidden';
import { Box, Button, Collapse, Dialog, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import DriveEta from '@material-ui/icons/DriveEta';
import { AccountType, UserRole } from '../../../reducers/auth/types';
import { GET_BOOKING } from '../../../graphql/bookings/getBookingQuery';
import Authenticator from "../../views/Auth";
import { ADD_DRIVERS_TO_BOOKING } from "../../../graphql/bookings/addDriversToBookingMutation";
import { IDriverRow } from "./PersonalDetails/CustomerView/AddNewDriver";
import _ from "lodash";
import { GET_AVAILABLE_SUBSCRIPTION_VEHICLES } from "../../../graphql/bookings/getAvailableVehiclesForSubscriptionsQuery";
import NewSubscription from "./Subscription/NewSubscription";
import { AddonsSelection } from "./SelectAddOns/AddonsSelection";
import { IBranch, IServiceLocation } from "../../../reducers/website/types";
import { IFilter } from "../../FilterSection/FilterSection";
import { NewTestDriveBooking } from "./TestDriveBooking/TestDriveBooking";

const useStyles = makeStyles((theme) => ({
  productTab: {
    position: 'relative',
    backgroundColor: 'transparent',
    border: 'none',
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(1.25, 0),
    color: "#fff",
    fontWeight: 600,
    display: "block",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#000",
      borderRadius: 12,
      '&:after': {
        content: '""',
        position: 'absolute',
        left: '50%',
        top: '100%',
        height: 16,
        width: 16,
        backgroundColor: '#fff',
        boxShadow: `2px 2px 4px 0 rgba(0, 0, 0, 0.16)`,
        borderRadius: 2,
        transform: 'translate(-50%, -50%) rotate(45deg)'
      }
    }
  },
  active: {
    boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.16)`,
    backgroundColor: '#fff',
    borderRadius: 12,
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#000",
      borderRadius: 12
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '100%',
      height: 16,
      width: 16,
      backgroundColor: '#fff',
      boxShadow: `2px 2px 4px 0 rgba(0, 0, 0, 0.16)`,
      borderRadius: 2,
      transform: 'translate(-50%, -50%) rotate(45deg)'
    }
  },
  backToSelectionScreen: {
    position: "absolute",
    left: 0,
    top: -40
  },
}))

export const initialValues: IBookingCreateInput = {
  customerType: "individual",
  pickupServiceLocation: "",
  dropoffServiceLocation: "",
  pickupDateTime: "",
  dropoffDateTime: "",
  flightNumber: "",
  longTermBooking: false,
  rateTypeName: "daily",
  billingCycleDuration: 1440,
  rateTypeDuration: 1440,
  vehicleGroups: [],
  insuranceRate: 0,
  excess: 0,
  addonRequirements: [],
  approvedDrivers: [],
  pcoNumber: false,
  locationSurcharges: [],
  isRecurringBilling: false,
  billingFrequency: 0,
  defaultBillingCycle: true
};

export const initialCustomerInfo: ICustomerInfoInput = {
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: {
    phone: "",
    country: "IN"
  },
  agreeToTerms: false,
  preBookingQuestions: [],
  customerNote: "",
  location: {
    city: "",
    country: "",
    fullAddress: "",
    state: "",
    street: "",
    zipcode: ""
  }
};

const stepsCompletedMapInitial = [false, false, false, false, false];

export const NewBooking: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const snackbar = useSnackBar();
  const [bookingData, setBookingData] = useState<IBookingCreateInput>(
    initialValues
  );
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const authState = useSelector((state: IAppState) => state.authReducer);
  const organisation = website.organisation;
  const { locale, currency } = organisation;
  const booking = useSelector((state: IAppState) => state.bookingReducer);
  const [bookingCreationFailed, setBookingCreationFailed] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsCompletedMap, setStepsCompletedMap] = useState<boolean[]>(stepsCompletedMapInitial);
  const [vehicleGroups, setVehicleGroups] = useState<IVehiclePriceGroup[]>([]);
  const [ownVehicleGroups, setOwnVehicleGroups] = useState<IVehiclePriceGroup[]>([]);
  const [sharedVehicleGroups, setSharedVehicleGroups] = useState<IVehiclePriceGroup[]>([]);
  const [locationSurcharges, setLocationSurcharges] = useState<ILocationSurchargeInput[]>([]);
  const [filterCriteriaData, setFilterCriteriaData] = useState<any[]>([]);
  const [ownFilterCriteria, setOwnFilterCriteria] = useState<any[]>([]);
  const [sharedFilterCriteria, setSharedFilterCriteria] = useState<any[]>([]);
  const [vehiclesSearched, setVehiclesSearched] = useState<boolean>(false);
  const [externalVehiclesSearched, setExternalVehiclesSearched] = useState<boolean>(false);
  const [requiredFieldUpdated, setRequiredFieldUpdated] = useState<boolean>(false);
  const [customerInfo, setCustomerInfo] = useState<ICustomerInfoInput>(initialCustomerInfo);
  const [floatingInfoExpanded, setFloatingInfoExpanded] = useState<boolean>(false);
  const [resetClicked, setResetClicked] = useState<boolean>(false);
  const [bookingStatus, setBookingStatus] = useState<string>("");
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [verificationDialog, setVerificationDialog] = useState(false);
  const [valetAddonType, setValetAddonType] = useState<string>("");
  const privacyPolicyURL = website.privacyPolicyURL || ''
  const termsAndConditionsURL = website.termsAndConditionsURL || ""
  const [bookingDrivers, setBookingDrivers] = useState<IDriverRow[]>([])
  const [newAddedDriversId, setNewAddedDriversId] = useState<string[]>([]);
  const driversIdRef = useRef<string[]>([]);
  const [oneWayRentalFee, setOneWayRentalFee] = useState<IOneWayRentalFee>();
  const [subscriptionVehicles, setSubscriptionVehicles] = useState<IVehicle[]>([]);
  const [bookingType, setBookingType] = useState<BookingType>(BookingType.RENTAL)
  const [showBookingTypeSelection, setShowBookingTypeSelection] = useState<boolean>(false)

  const [loadVehicleGroups,
    { loading: vehicleGroupsLoading,
      data: availableVehicleGroupsData }
  ] = useLazyQuery(
    GET_AVAILABLE_VEHICLE_GROUPS, {
    fetchPolicy: "network-only",
    onError(error) {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [loadExternalVehicleGroups,
    { loading: externalVehicleGroupsLoading,
      data: externalAvailableVehicleGroupsData }
  ] = useLazyQuery(
    GET_EXTERNAL_AVAILABLE_VEHICLE_GROUPS, {
    fetchPolicy: "network-only",
    onError(error) {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [loadVehicles,
    { loading: vehiclesLoading,
      data: availableVehiclesData }
  ] = useLazyQuery(
    GET_AVAILABLE_SUBSCRIPTION_VEHICLES, {
    fetchPolicy: "network-only",
    onError(error) {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  function getSteps() {
    const steps = ['Select Vehicles', 'Personal details'];
    if (!organisation.closeGroupSharingEnabled) {
      steps.splice(1, 0, 'Select Add-ons');
    }
    if (bookingType === BookingType.TEST_DRIVE) {
      steps.splice(1, 1);
    }
    return steps;
  }
  const steps = getSteps();

  const [loadVehicleCriteria, { data: criteriaData }] = useLazyQuery(
    GET_VEHICLE_GROUP_CRITERIA,
    {
      fetchPolicy: "network-only"
    }
  );

  const [loadExternalVehicleCriteria, { data: externalCriteriaData }] = useLazyQuery(
    GET_EXTERNAL_VEHICLE_GROUP_CRITERIA,
    {
      fetchPolicy: "network-only"
    }
  );

  const [addDriversToBooking] = useMutation(ADD_DRIVERS_TO_BOOKING, {
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [createBookingMutation, { data: bookingCreateData, loading: bookingCreating }] = useMutation(
    CREATE_BOOKING,
    {
      onCompleted: (data) => {
        dispatch(updateBookingAction(data.consumerCreateBooking));
        if (bookingData.bookingCategory === BookingType.TEST_DRIVE) {
          history.push(`/booking?id=${data.consumerCreateBooking.id}`);
          snackbar({
            message: "Booking created",
            variant: SnackBarVariant.SUCCESS
          });
        } else {
          history.push(`/quote?id=${data.consumerCreateBooking.id}`)
          if (newAddedDriversId.length || driversIdRef.current.length) {
            addDriversToBooking({
              variables: {
                bookingId: data.consumerCreateBooking.id,
                drivers: newAddedDriversId.length ? newAddedDriversId : driversIdRef.current
              }
            })
          }
          snackbar({
            message: "Quote created",
            variant: SnackBarVariant.SUCCESS
          });
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        setBookingCreationFailed(true);
      }
    }
  );

  const [
    loadBooking,
    { loading: bookingLoading, data: bookingInfoData }
  ] = useLazyQuery(GET_BOOKING, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (!data.consumerGetBooking) {
        history.goBack()
      }
      if (data.consumerGetBooking && data.consumerGetBooking.status) {
        setBookingStatus(data.consumerGetBooking.status)
      }
      if (data.consumerGetBooking && data.consumerGetBooking.approvedDrivers.length) {
        setBookingDrivers(data.consumerGetBooking.approvedDrivers)
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const loadDefaultBookingCategory = () => {
    const { bookingTypes, pcoRentalOnly } = website;
    if (!bookingTypes || bookingTypes.length === 1) {
      setShowBookingTypeSelection(false);
      setBookingType(bookingTypes[0] as BookingType)
    } else {
      setShowBookingTypeSelection(true);
      const sortedBookingTypes: BookingType[] = [...bookingTypes as BookingType[]].sort();
      setBookingType(sortedBookingTypes[0]);
      for (const type of sortedBookingTypes) {
        bookingCategories[type as BookingType].enabled = true;
      }
    }
    setBookingData({
      ...bookingData,
      pcoNumber: pcoRentalOnly
    });
  }

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const bookingId = params.get("booking");
      const bookingType = params.get("bookingType");
      if (bookingType) {
        setBookingType(bookingType as BookingType);
        setShowBookingTypeSelection(false);
      } else {
        loadDefaultBookingCategory();
      }
      if (bookingId) {
        setIsUpdate(true);
        return loadBooking({
          variables: {
            bookingId: bookingId
          }
        });
      }
    } else {
      loadDefaultBookingCategory();
    }
  }, [location, website.organisation.allowSubscriptionBookings, website.bookingTypes]);


  useEffect(() => {
    if (availableVehicleGroupsData && availableVehicleGroupsData.consumerAvailableVehicleGroups) {
      setRequiredFieldUpdated(false);
      setOwnVehicleGroups(availableVehicleGroupsData.consumerAvailableVehicleGroups.vehicleGroups);
      setOneWayRentalFee(availableVehicleGroupsData.consumerAvailableVehicleGroups.oneWayRentalFee);
      const vehiclesDistanceArr = availableVehicleGroupsData.consumerAvailableVehicleGroups.vehiclesDistance;
      let _vehicleGroups = [...availableVehicleGroupsData.consumerAvailableVehicleGroups.vehicleGroups, ...sharedVehicleGroups];
      if (website && website.organisation.crossLocationBookingEnabled) {
        _vehicleGroups = _vehicleGroups.map((vg) => {
          const tempVg = JSON.parse(JSON.stringify(vg));
          for (let index = 0; index < tempVg.vehicles.length; index++) {
            const vehicle = tempVg.vehicles[index];
            for (let j = 0; j < vehiclesDistanceArr.length; j++) {
              const vd = vehiclesDistanceArr[j];
              if (vehicle.id === vd.vehicleId) {
                tempVg.vehicles[index] = {
                  ...tempVg.vehicles[index],
                  awayDistance: vd.distance
                }
              }
            }
          }
          tempVg.vehicles = _.sortBy(tempVg.vehicles, (v) => v.awayDistance);
          return tempVg;
        })
      }
      setVehicleGroups(_vehicleGroups);
      setLocationSurcharges(availableVehicleGroupsData.consumerAvailableVehicleGroups.locationSurcharges);
      setVehiclesSearched(true);
    }
  }, [availableVehicleGroupsData]);

  useEffect(() => {
    if (externalAvailableVehicleGroupsData && externalAvailableVehicleGroupsData.consumerExternalAvailableVehicleGroups) {
      setRequiredFieldUpdated(false);
      setSharedVehicleGroups(externalAvailableVehicleGroupsData.consumerExternalAvailableVehicleGroups.vehicleGroups);
      const _vehicleGroups = [...externalAvailableVehicleGroupsData.consumerExternalAvailableVehicleGroups.vehicleGroups, ...ownVehicleGroups];
      setVehicleGroups(_vehicleGroups);
      setExternalVehiclesSearched(true);
    }
  }, [externalAvailableVehicleGroupsData]);

  useEffect(() => {
    if (availableVehiclesData && availableVehiclesData.getConsumerSubscriptionCatalogue) {
      setRequiredFieldUpdated(false)
      setExternalVehiclesSearched(true)
      setSubscriptionVehicles(availableVehiclesData.getConsumerSubscriptionCatalogue)
      setVehiclesSearched(true)
    }
  }, [availableVehiclesData])

  useEffect(() => {
    if (criteriaData && criteriaData.consumerVehicleGroupCriteria && criteriaData.consumerVehicleGroupCriteria.length) {
      const { consumerVehicleGroupCriteria } = criteriaData;
      if (consumerVehicleGroupCriteria) {
        const fetchedCriteria = formatCriteriaForFilter(consumerVehicleGroupCriteria);
        setOwnFilterCriteria(fetchedCriteria)
        const filtersData = mergeFiltersArray(fetchedCriteria, sharedFilterCriteria)
        setFilterCriteriaData(filtersData)
      }
    }
  }, [criteriaData]);

  useEffect(() => {
    if (externalCriteriaData && externalCriteriaData.consumerExternalVehicleGroupCriteria && externalCriteriaData.consumerExternalVehicleGroupCriteria.length) {
      const { consumerExternalVehicleGroupCriteria } = externalCriteriaData;
      if (consumerExternalVehicleGroupCriteria) {
        const fetchedCriteria = formatCriteriaForFilter(consumerExternalVehicleGroupCriteria);
        setSharedFilterCriteria(fetchedCriteria)
        const filtersData = mergeFiltersArray(fetchedCriteria, ownFilterCriteria)
        setFilterCriteriaData(filtersData)
      }
    }
  }, [externalCriteriaData]);

  useEffect(() => {
    if (booking.createBookingInput) {
      setBookingData(booking.createBookingInput);
    }
  }, [booking.createBookingInput]);

  useEffect(() => {
    if (bookingInfoData && bookingInfoData.consumerGetBooking) {
      const _booking = bookingInfoData.consumerGetBooking;
      if (booking && booking.booking.pickupDateTime) {
        _booking.pickupDateTime = booking.booking.pickupDateTime
        _booking.dropoffDateTime = booking.booking.dropoffDateTime
      }
      setRequiredFieldUpdated(true);
      const businessCustomerId = _booking.businessCustomer
        ? _booking.businessCustomer.id
          ? _booking.businessCustomer.id
          : _booking.businessCustomer
        : "";
      loadVehicleCriteria({
        variables: { branchId: _booking.branchId }
      })
      formatAndSetBookingData(_booking);
      if (!_booking.isSubscription) {
        loadVehicleGroups({
          variables: {
            input: {
              endDate: dayjs(_booking.dropoffDateTime),
              startDate: dayjs(_booking.pickupDateTime),
              rateTypeDuration: _booking.rateTypeDuration,
              longTerm: _booking.longTermBooking,
              businessCustomer: businessCustomerId,
              bookingId: _booking.id,
              branchId: _booking.branchId,
              serviceLocation: _booking.pickupServiceLocation.id,
              dropoffServiceLocation: _booking.dropoffServiceLocation.id
            }
          }
        });
        loadExternalVehicleGroups({
          variables: {
            input: {
              endDate: dayjs(_booking.dropoffDateTime),
              startDate: dayjs(_booking.pickupDateTime),
              rateTypeDuration: _booking.rateTypeDuration,
              longTerm: _booking.longTermBooking,
              businessCustomer: businessCustomerId,
              bookingId: _booking.id,
              branchId: _booking.branchId,
              serviceLocation: _booking.pickupServiceLocation.id
            }
          }
        });
      } else {
        loadVehicles({
          variables: {
            input: {
              endDate: dayjs(_booking.dropoffDateTime),
              startDate: dayjs(_booking.pickupDateTime),
              branchId: _booking.branchId,
              bookingId: _booking.id || "",
            }
          }
        });
      }
    }
  }, [bookingInfoData, bookingType]);

  const formatAndSetBookingData = (booking: IBooking) => {
    const vehicleGroups: IBookingVehicleGroup[] = booking.vehicleGroups.map(
      (vg: IBookingVehicleGroup) => {
        let branchId = "";
        if (vg.activeVehicleSchedules?.length) {
          branchId = vg.activeVehicleSchedules[0].vehicle.branch;
        }
        delete vg.activeVehicleSchedules;
        return {
          ...vg,
          unlimitedMileage: vg.unlimitedMileage || false,
          vehicleGroup: (vg.vehicleGroup as IBookingVehiclePriceGroup).id
        };
      }
    );
    const addonRequirements: IAddonRequirementInput[] = booking.addonRequirements.map(
      (addonR: IBookingAddonRequirement) => {
        return {
          addon: addonR.addon.id,
          quantity: addonR.quantity,
          name: addonR.name,
          hasFixedRate: addonR.hasFixedRate,
          rate: addonR.rate,
          displayName: addonR.displayName,
          tax: {
            title: addonR.tax && addonR.tax.title ? addonR.tax.title : "",
            value: addonR.tax && addonR.tax.value ? addonR.tax.value : 0
          }
        };
      }
    );
    const bookingObj: IBookingCreateInput = {
      id: booking.id,
      pickupServiceLocation: booking.pickupServiceLocation?.id,
      dropoffServiceLocation: booking.dropoffServiceLocation?.id,
      pickupOtherLocation: booking.pickupOtherLocation,
      dropoffOtherLocation: booking.dropoffOtherLocation,
      pickupDateTime: booking.pickupDateTime,
      dropoffDateTime: booking.dropoffDateTime,
      longTermBooking: booking.longTermBooking,
      flightNumber: booking.flightNumber,
      vehicleGroups,
      insuranceRate: booking.insuranceRate,
      excess: booking.excess,
      insurancePolicy: booking.insurancePolicy?.id,
      addonRequirements,
      customer: booking.customer?.id,
      customerType: booking.customerType,
      approvedDrivers: booking.approvedDrivers,
      rateTypeName: booking.rateTypeName,
      rateTypeDuration: booking.rateTypeDuration,
      billingCycleName: booking.billingCycleName,
      billingCycleDuration: booking.billingCycleDuration,
      businessCustomer: booking.businessCustomer?.id,
      authorizedSignatory: booking.authorizedSignatory,
      pcoNumber: booking.pcoNumber,
      preBookingQuestions: booking.preBookingQuestions,
      deliveryQuestions: booking.deliveryQuestions,
      source: booking.source ? booking.source : BOOKING_SOURCE.B2B2C,
      locationSurcharges: booking.locationSurcharges,
      smartCarVehicle: booking.smartCarVehicle,
      isCarShare: booking.isCarShare || false,
      status: booking.status,
      poNumber: booking.poNumber,
      isRecurringBilling: booking.isRecurringBilling,
      billingFrequency: booking.billingFrequency,
      branchId: booking.branchId,
      isSubscription: booking.isSubscription || false,
      activeSubscriptionExpanded: booking.activeSubscription,
      bookingCategory: booking.bookingCategory,
    };
    if (booking.activeSubscription) {
      bookingObj.activeSubscription = {
        startDate: booking.activeSubscription.startDate,
        endDate: booking.activeSubscription.endDate,
        subscription: booking.activeSubscription.subscription.id,
        vehicle: booking.activeSubscription.vehicle.id || "",
        duration: booking.activeSubscription.duration,
        subscriptionAmount: booking.activeSubscription.subscriptionAmount,
        enrollmentAmount: booking.activeSubscription.enrollmentAmount,
        unlimitedMileage: booking.activeSubscription.unlimitedMileage ? true : false,
        mileageLimit: booking.activeSubscription.mileageLimit || 0,
        pricePerExtraMile: booking.activeSubscription.pricePerExtraMile || 0
      }
    }
    setCustomerInfo({ ...customerInfo, preBookingQuestions: booking.preBookingQuestions })
    setBookingData(bookingObj);
  };

  const previousStep = () => {
    if (currentStep > 0) {
      if (
        (website.organisation.closeGroupSharingEnabled && currentStep === BookingFlowStage.PERSONAL_DETAILS) ||
        bookingType === BookingType.TEST_DRIVE
      ) {
        setCurrentStep(currentStep - 2);
      } else {
        setCurrentStep(currentStep - 1);
      }
    }
  };
  const handleStep = (index: number) => {
    if (index < currentStep) {
      setCurrentStep(index);
      const tempStepsCompMap = [...stepsCompletedMap];
      for (var i = index + 1; i < stepsCompletedMap.length; i++) {
        tempStepsCompMap[i] = false
      }
      setStepsCompletedMap(tempStepsCompMap);
    }
  }
  const nextStep = (data: IBookingCreateInput, updateOnly?: boolean) => {
    driversIdRef.current = data.approvedDrivers
    delete data.defaultBillingCycle;
    const tempStepsCompMap = [...stepsCompletedMap];
    if (data) {
      setBookingData(data);
    }
    if (!updateOnly) {
      const locationActive = website.branches.some((branch: IBranch) =>
        branch.activeLocations.some((location: IServiceLocation) =>
          data.pickupServiceLocation === location.id
        )
      );
      if (!locationActive) {
        snackbar({
          message: "Pick-Up Location is empty",
          variant: SnackBarVariant.ERROR
        });
        setCurrentStep(currentStep)
        return;
      }
      if (currentStep <= Object.keys(stepMapper).length - 1) {
        if (currentStep === BookingFlowStage.ADDONS_SELECTION && website.organisation.requiresCustomerVerification && !user?.id) {
          setVerificationDialog(true);
        } else if (currentStep === BookingFlowStage.PERSONAL_DETAILS) {
          const booking = {
            ...bookingData,
            ...data
          };
          booking.vehicleGroups = booking.vehicleGroups.filter((item) => item.vehicleIds.length > 0);
          // if (bookingStatus === BookingStatus.QUOTE) {
          //   updateBooking(booking);
          // } else {
          //   createBooking({ ...booking, ...(booking.id && { bookingId: booking.id }) });
          // }
          createBooking({ ...booking, ...(booking.id && { bookingId: booking.id }) });
          // createBooking(data);
        } else if (currentStep === BookingFlowStage.PAYMENT_VIEW) {
          if (!data) {
            snackbar({
              message: "Payment already done",
              variant: SnackBarVariant.INFORMATION
            });
          }
        } else {
          tempStepsCompMap[currentStep] = true;
          setStepsCompletedMap(tempStepsCompMap);
          if (website.organisation.closeGroupSharingEnabled || bookingType === BookingType.TEST_DRIVE) {
            setCurrentStep(currentStep + 2);
          }
          else {
            setCurrentStep(currentStep + 1);
          }
        }
      }
    }
  };

  const createBooking = (bookingInput: IBookingCreateInput) => {
    const addonRequirements = bookingInput.addonRequirements.map(
      (addonReq: IAddonRequirementInput) => {
        return {
          addon: addonReq.addon,
          hasFixedRate: addonReq.hasFixedRate,
          name: addonReq.name,
          displayName: addonReq.displayName,
          quantity: addonReq.quantity,
          rate: addonReq.rate,
          tax: {
            title: addonReq.tax && addonReq.tax.title ? addonReq.tax.title : "",
            value: addonReq.tax && addonReq.tax.value ? addonReq.tax.value : 0
          }
        };
      }
    );
    const booking: any = {
      ...(bookingInput.bookingId && { bookingId: bookingInput.bookingId }),
      pickupServiceLocation: bookingInput.pickupServiceLocation,
      pickupDateTime: dayjs(bookingInput.pickupDateTime).toISOString(),
      dropoffServiceLocation: bookingInput.dropoffServiceLocation,
      dropoffDateTime: bookingInput.dropoffDateTime
        ? dayjs(bookingInput.dropoffDateTime).toISOString()
        : bookingInput.dropoffDateTime,
      vehicleGroups: bookingInput.vehicleGroups,
      insuranceRate: bookingInput.insuranceRate,
      excess: bookingInput.excess,
      insurancePolicy: bookingInput.insurancePolicy,
      addonRequirements,
      customer: bookingInput.customer,
      businessCustomer: bookingInput.businessCustomer,
      rateTypeName: bookingInput.rateTypeName,
      rateTypeDuration: bookingInput.rateTypeDuration,
      preBookingQuestions: bookingInput.preBookingQuestions,
      customerNote: bookingInput.customerNote,
      smartCarVehicle: bookingInput.smartCarVehicle,
      isCarShare: bookingInput.isCarShare,
      locationSurcharges: bookingInput.locationSurcharges,
      createB2B2CUser: true,
      isRecurringBilling: bookingInput.isRecurringBilling,
      billingFrequency: bookingInput.billingFrequency,
      isSubscription: bookingInput.isSubscription || false,
      isCoi: bookingInput.isCoi || false,
      pickupOtherLocation: bookingInput.pickupOtherLocation,
      dropoffOtherLocation: bookingInput.dropoffOtherLocation,
      bookingCategory: bookingInput.bookingCategory,
      pcoNumber: bookingInput.pcoNumber || false,
    };
    if (bookingInput.costCenter) {
      booking.costCenter = bookingInput.costCenter;
      if (bookingInput.projectId) {
        booking.projectId = bookingInput.projectId;
      }
    }
    if (bookingInput.isSubscription) {
      booking.activeSubscription = bookingInput.activeSubscription
      booking.locationSurcharges = []
    }
    setBookingCreationFailed(false);
    createBookingMutation({
      variables: {
        branchId: bookingInput.branchId,
        booking
      }
    });
  };

  const searchVehicles = (data: IBookingCreateInput) => {
    setVehicleGroups([]);
    setVehiclesSearched(false);
    setExternalVehiclesSearched(false);
    if (dayjs().isAfter(dayjs(data.dropoffDateTime))) {
      data.dropoffDateTime = undefined;
    }
    if (!data.dropoffDateTime) {
      data.longTermBooking = true;
      delete data.dropoffDateTime;
    }
    loadVehicleCriteria({
      variables: { branchId: data.branchId },
    });
    loadExternalVehicleCriteria({
      variables: { branchId: data.branchId },
    });
    if ([BookingType.RENTAL, BookingType.TEST_DRIVE].includes(bookingType)) {
      loadVehicleGroups({
        variables: {
          input: {
            endDate: dayjs(data.dropoffDateTime),
            startDate: dayjs(data.pickupDateTime),
            rateTypeDuration: data.rateTypeDuration,
            branchId: data.branchId,
            businessCustomer: data.businessCustomer ? data.businessCustomer : authState.user?.businesscustomers?.id,
            bookingId: data.id || "",
            serviceLocation: data.pickupServiceLocation,
            dropoffServiceLocation: data.dropoffServiceLocation,
            pcoNumber: data.pcoNumber || false,
          }
        }
      });
      loadExternalVehicleGroups({
        variables: {
          input: {
            endDate: dayjs(data.dropoffDateTime),
            startDate: dayjs(data.pickupDateTime),
            rateTypeDuration: data.rateTypeDuration,
            branchId: data.branchId,
            businessCustomer: data.businessCustomer ? data.businessCustomer : authState.user?.businesscustomers?.id,
            bookingId: data.id || "",
            serviceLocation: data.pickupServiceLocation,
            pcoNumber: data.pcoNumber || false,
          }
        }
      });
    } else {
      loadVehicles({
        variables: {
          input: {
            endDate: dayjs(data.dropoffDateTime),
            startDate: dayjs(data.pickupDateTime),
            branchId: data.branchId,
            bookingId: data.id || "",
            pcoNumber: data.pcoNumber || false,
          }
        }
      });
    }

    const { insuranceRate, insuranceName, insurancePolicy, addonRequirements, ...rest } = bookingData
    setBookingData({
      ...rest,
      ...data,
      vehicleGroups: [],
      insuranceName: "",
      insurancePolicy: "",
      insuranceRate: 0,
      addonRequirements: []
    });
    if (currentStep > 0) {
      setCurrentStep(0);
    }
  };

  const updateParentState = (newValue: string) => {
    setValetAddonType(newValue);
  };

  const formatCriteriaForFilter: any = (data: any) => {
    const filter = {} as any;
    for (let i = 0; i < data.length; i++) {
      if (data[i].values?.length) {
        let values = data[i].values.map((value: string) => value.toLowerCase())
        values = Array.from(new Set(values))
        filter[data[i].type] = {
          id: data[i].type,
          name: _.startCase(data[i].type),
          options: values.map((val: string) => {
            const formattedName = val.split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
            return {
              id: val,
              name: formattedName
            }
          })
        };
      }
    }
    return Object.values(filter);
  };

  const mergeFiltersArray = (arr1: IFilter[], arr2: IFilter[]) => {
    const data: IFilter[] = [];
    arr1.forEach(item => {
      if (item.options) {
        const idx: number = data.findIndex(ele => ele.id === item.id);
        if (idx < 0) {
          data.push(item);
        } else {
          const arrItem = { ...data[idx] };
          if (arrItem) {
            item.options.forEach((option) => {
              const itemIdx = arrItem.options.findIndex(ele => ele.id === option.id)
              if (itemIdx < 0) {
                arrItem.options.push(option)
              }
            })
            data[idx] = arrItem;
          }
        }
      }
    })
    arr2.forEach(item => {
      if (item.options) {
        const idx: number = data.findIndex(ele => ele.id === item.id);
        if (idx < 0) {
          data.push(item);
        } else {
          const arrItem = { ...data[idx] };
          if (arrItem) {
            item.options.forEach((option) => {
              const itemIdx = arrItem.options.findIndex(ele => ele.id === option.id)
              if (itemIdx < 0) {
                arrItem.options.push(option)
              }
            })
            data[idx] = arrItem;
          }
        }
      }
    })
    const yearIdx = data.findIndex(ele => ele.id === "year");
    if (yearIdx) {
      const yearItem = data[yearIdx];
      if (yearItem && yearItem.options) {
        yearItem.options = yearItem.options.sort((a, b) => parseInt(a.id) - parseInt(b.id));
      }
      data[yearIdx] = yearItem
    }
    return data;
  }

  const stepMapper: any = {
    0: ((vehicleGroups.length || subscriptionVehicles.length) > 0 ?
      <VehicleSelection
        vehicleGroupData={vehicleGroups}
        bookingData={bookingData}
        values={bookingData}
        setValues={setBookingData}
        criteriaData={filterCriteriaData}
        locationSurcharges={locationSurcharges}
        oneWayRentalFee={oneWayRentalFee}
        bookingType={bookingType}
        subscriptionVehicles={subscriptionVehicles}
      />
      :
      <p className="flex main-center font-regular">
        No vehicles found matching the selected criteria
      </p>
    ),
    1: (
      <AddonsSelection
        values={bookingData}
        setValues={setBookingData}
        onSubmit={nextStep}
        onPrevious={previousStep}
        updateParentState={updateParentState}
        oneWayRentalFee={oneWayRentalFee}
      />
    ),
    2: (bookingCreating ?
      <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
      :
      <PersonalDetails
        bookingData={bookingData}
        onSubmit={nextStep}
        onPrevious={previousStep}
        customerInfo={customerInfo}
        bookingCreationFailed={bookingCreationFailed}
        bookingDrivers={bookingDrivers}
        oneWayRentalFee={oneWayRentalFee}
        bookingType={bookingType}
        updateBookingDrivers={(drivers) => {
          if (drivers.length) {
            const driversId = drivers
              .filter((driver) => typeof driver.id === 'string')
              .map((driver) => driver.id as string);

            if (driversId.length) {
              if (bookingData?.approvedDrivers?.length) {
                const newDriversId = driversId.filter((id) => !bookingData.approvedDrivers.some((approvedDriver: IDriverRow) => approvedDriver.id === id));

                setNewAddedDriversId(newDriversId);
                driversIdRef.current = newDriversId;
              } else {
                setNewAddedDriversId(driversId);
                driversIdRef.current = driversId;
              }
            }
            setBookingDrivers(drivers);
          }
        }}
      />
    ),
  };

  const getSelectedVehiclesCount = () => {
    let count = 0;
    if (bookingData.isSubscription) {
      count = 1
    } else {
      bookingData.vehicleGroups.forEach((group) => {
        count += group.vehicleIds.length;
      })
    }
    return count;
  }
  const getContinueButtonDisableStatus = () => {
    if (requiredFieldUpdated) {
      return true
    }
    if (currentStep === 0) {
      if (bookingData.isSubscription) {
        return false
      } else {
        return !bookingData.vehicleGroups.some((item) => item.count > 0)
      }
    }
    if (currentStep === 2) {
      if (bookingData.isSubscription) {
        return false
      } else {
        return !bookingData.vehicleGroups.some((item) => item.count > 0)
      }
    }
    return false;
  }

  const handleLocationSelection = (locationId: string, branchId: string) => {
    setBookingData({
      ...bookingData,
      pickupServiceLocation: locationId,
      branchId: branchId
    });
  }

  const getAddonCount = () => {
    let count = 0;
    bookingData.addonRequirements.forEach((addon) => {
      count += addon.quantity;
    })
    return count
  }

  const resetSearch = () => {
    setCurrentStep(0)
    setVehiclesSearched(false);
    setExternalVehiclesSearched(false);
    setResetClicked(true);
    setStepsCompletedMap(stepsCompletedMapInitial);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  const resetStatesOnChangeBookingType = () => {
    const { activeSubscription, activeSubscriptionExpanded, isSubscription, ...rest } = bookingData
    setBookingData({
      ...rest,
      pickupDateTime: "",
      dropoffDateTime: "",
      pickupServiceLocation: "",
      dropoffServiceLocation: "",
    })
    setVehiclesSearched(false)
    setExternalVehiclesSearched(false)
    setRequiredFieldUpdated(false)
    setSubscriptionVehicles([])
    setVehicleGroups([])
    setResetClicked(false)
  }
  
  const logoSize = website.logoSize || 40
  const topBarHeight = logoSize > 50 ? logoSize + 20 : 70

  if (bookingLoading) {
    return (
      <>
        <TopBar
          height={((vehicleGroupsLoading || externalVehicleGroupsLoading) || vehiclesSearched) ? 50 : topBarHeight}
          logoSize={((vehicleGroupsLoading || externalVehicleGroupsLoading) || vehiclesSearched) ? 30 : logoSize}
        />
        <div className="flex main-center cross-center" style={{ height: 300 }}>
          <CircularProgress />
        </div>
      </>
    )
  }

  const renderBookingCategory = (bookingType: BookingType) => {
    switch(bookingType) {
      case BookingType.RENTAL:
        return <> {
          (authState.accountType === AccountType.BUSINESS && user?.role !== UserRole.DRIVER) || authState.accountType === AccountType.INDIVIDUAL || !user ? <>
            <Hidden smDown>
              <BaseDetails
                bookingData={bookingData}
                values={bookingData}
                requiredFieldUpdated={requiredFieldUpdated}
                setRequiredFieldUpdated={setRequiredFieldUpdated}
                searchVehicles={searchVehicles}
                position={((vehicleGroupsLoading || externalVehicleGroupsLoading) || vehiclesSearched) ? 50 : topBarHeight}
                height={((vehicleGroupsLoading || externalVehicleGroupsLoading) || vehiclesSearched) ? 5 : 10}
                isBookingUpdate={isUpdate}
                resetSearch={resetSearch}
                setValues={setBookingData}
                handleSelectionBack={() => {
                  setShowBookingTypeSelection(true)
                  resetStatesOnChangeBookingType()
                  setCurrentStep(0)
                }}
                bookingType={bookingType}
              />
            </Hidden>
            <Hidden mdUp>
              <BaseDetails
                resetSearch={resetSearch}
                vehiclesSearched={vehiclesSearched || (vehicleGroupsLoading || externalVehicleGroupsLoading)}
                bookingData={bookingData}
                requiredFieldUpdated={requiredFieldUpdated}
                setRequiredFieldUpdated={setRequiredFieldUpdated}
                searchVehicles={searchVehicles}
                position={((vehicleGroupsLoading || externalVehicleGroupsLoading) || vehiclesSearched) ? 50 : topBarHeight}
                height={((vehicleGroupsLoading || externalVehicleGroupsLoading) || vehiclesSearched) ? 5 : 10}
                isBookingUpdate={isUpdate}
                setValues={setBookingData}
                handleSelectionBack={() => setShowBookingTypeSelection(true)}
                values={bookingData}
              />
            </Hidden>
          </> : <div className={styles.searchBox} style={{ marginTop: 70, padding: 15, position: 'initial', top: topBarHeight }} />}
          <div className={styles.appBody}>
            {currentStep === 0 ?
              (vehicleGroupsLoading || externalVehicleGroupsLoading || vehiclesLoading) ?
                <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress />
                </div>
                :
                (vehiclesSearched && externalVehiclesSearched && !requiredFieldUpdated) ?
                  <div className={styles.actionField}>
                    {stepMapper[currentStep]}
                  </div>
                  :
                  resetClicked ? <div /> : <PageModules setLocation={handleLocationSelection} showCarousel={true} bookingType={bookingType as BookingType} />
              :
              (!requiredFieldUpdated ? <div className={styles.actionField}>
                {stepMapper[currentStep]}
              </div> : (resetClicked ? <div style={{ height: 300 }} /> : <PageModules setLocation={handleLocationSelection} showCarousel={true} bookingType={bookingType as BookingType} />))
            }
          </div>
          <Slide direction="up" in={((vehicleGroupsLoading || externalVehicleGroupsLoading) || vehiclesSearched)} mountOnEnter unmountOnExit>
            <div className={`${styles.bottomBar} ${styles.mobile}`}>
              <Hidden mdUp>
                {getSelectedVehiclesCount() > 0 &&
                  <div className={`${styles.floatingInfo} ${floatingInfoExpanded ? styles.open : ''}`}>
                    <div className={styles.header} >
                      <div className={styles.selectionCount}>
                        <span className="bold"><span className="padding-right">Selected - </span> Vehicles: {getSelectedVehiclesCount()}</span>
                        {bookingData.addonRequirements.length ? <span className="bold padding-left">Add-on: {getAddonCount()}</span> : null}
                      </div>
                      <span className="bold font-medium flex cross-center">
                      </span>
                    </div>
                    <Collapse in={floatingInfoExpanded}>
                      <div className={styles.info}>
                        {bookingData.vehicleGroups.map(vg => (
                          <span className="flex padding-bottom font-medium">
                            <span style={{ width: '75%', textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: 20 }}>
                              <DriveEta style={{ fontSize: 18, verticalAlign: 'top', color: '#848484', marginRight: 5 }} /><span>{vg.name}</span>
                            </span>
                            <span style={{ width: '5%', }}>
                              {vg.vehicleIds.length}
                            </span>
                            <span style={{ width: '20%', textAlign: 'right' }}>
                              {toCurrency((vg.applicableAmount ?? vg.baseRate) * vg.vehicleIds.length, currency, locale)}
                            </span>
                          </span>
                        ))}
                        {bookingData.insuranceName ? <span className="flex padding-bottom font-medium">
                          <span style={{ width: '75%', textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: 20 }}>
                            <img
                              style={{ width: 14, height: 14, margin: '0 9px 0 2px', verticalAlign: 'middle' }}
                              src={getAddonIcon("insurance")}
                              alt="Insurance"
                            />
                            <span>{bookingData.insuranceName}</span>
                          </span>
                          <span style={{ width: '5%', }}>
                            1
                          </span>
                          <span style={{ width: '20%', textAlign: 'right' }}>
                            {toCurrency(bookingData.insuranceRate, currency, locale)}
                          </span>
                        </span> : null}
                        {bookingData.addonRequirements.map(addon => (
                          <span className="flex padding-bottom font-medium">
                            <span style={{ width: '75%', textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: 20 }}>
                              <img
                                style={{ width: 14, height: 14, margin: '0 9px 0 2px', verticalAlign: 'middle' }}
                                src={getAddonIcon("custom-addon")}
                                alt="custom addon"
                              />
                              <span>{addon.displayName}</span>
                            </span>
                            <span style={{ width: '5%', }}>
                              {addon.quantity}
                            </span>
                            <span style={{ width: '20%', textAlign: 'right' }}>
                              {toCurrency(addon.quantity * addon.rate, currency, locale)}
                            </span>
                          </span>
                        ))}
                      </div>
                    </Collapse>
                  </div>
                }
              </Hidden>
              <Grid container>
                <Hidden smDown>
                  <Grid item md={7} lg={5}>
                    <div className="flex cross-center" style={{ height: "100%" }}>
                      <Stepper nonLinear activeStep={currentStep}>
                        {steps.map((label, index) => {
                          const stepProps = {};
                          return (
                            <Step key={label} {...stepProps}>
                              <StepButton onClick={() => handleStep(index)} completed={stepsCompletedMap[index]}>
                                {label}
                              </StepButton>
                            </Step>
                          );
                        })}
                      </Stepper>
                    </div>
                  </Grid>
                </Hidden>
                <Grid item lg={3}></Grid>
                {bookingCreating ?
                  <Grid item xs={12} md={5} lg={4}></Grid> :
                  <Grid item xs={12} md={5} lg={4} alignItems="center" justify="space-between">
                    <div className="flex cross-center main-end">
                      <Hidden smDown>
                        {currentStep === 0 &&
                          <div className={styles.selectionCount}>
                            {getSelectedVehiclesCount() > 0 &&
                              <CheckCircleRoundedIcon style={{ fontSize: 24, marginRight: 5 }} />
                            }
                            <span className="bold">Vehicles selected: {getSelectedVehiclesCount()}</span>
                          </div>
                        }
                        <span></span>
                      </Hidden>
                      {
                        (website.bookingTypes.length > 1 && (
                          <div className={classes.backToSelectionScreen}>
                            <Tooltip title="Go back to booking categories selection.">
                              <IconButton
                                onClick={() => setShowBookingTypeSelection(true)}
                              >
                                <ArrowBackIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )
                        )
                      }
                      {currentStep > 0 &&
                        <button
                          className={styles.prevStepButton}
                          onClick={() => previousStep()}
                        >
                          <KeyboardArrowLeftIcon /><span className="padding-right">Back</span>
                        </button>
                      }
                      <Hidden mdUp>
                        <button
                          className={`${styles.nextStepButton} ${styles.mobile}`}
                          onClick={() => nextStep(bookingData)}
                          disabled={getContinueButtonDisableStatus()}
                        >Continue</button>
                      </Hidden>
                      <Hidden smDown>
                        <button
                          className={`${styles.nextStepButton}`}
                          onClick={() => nextStep(bookingData)}
                          disabled={getContinueButtonDisableStatus()}
                        >Continue</button>
                      </Hidden>
                    </div>
                  </Grid>
                }
              </Grid>
            </div>
          </Slide>
          {privacyPolicyURL.length && !vehiclesSearched && !(vehicleGroupsLoading || externalVehicleGroupsLoading) ? <div className={styles.footer}>
            <Container>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Typography variant="h4" className={styles.center}>
                    {website.appName}
                  </Typography>
                </Grid>
                <Hidden mdUp>
                  <Grid style={{ margin: 5 }} ></Grid>
                </Hidden>
                <Grid container item xs={12} md={8} >
                  <Grid item xs={12} justify="flex-end" className={`${styles.center} flex`}>
                    <span>
                      <a rel="noreferrer" target="_blank" className={styles.footerUrl} href={privacyPolicyURL}>
                        <span>
                          <span style={{ paddingRight: 5 }}>Privacy Policy</span>
                          <LaunchIcon style={{ fontSize: 16, verticalAlign: 'middle' }} />
                        </span>
                      </a>
                      <a rel="noreferrer" target="_blank" className={styles.footerUrl} href={termsAndConditionsURL}>
                        <span>
                          <span style={{ paddingRight: 5 }}>Terms and Conditions</span>
                          <LaunchIcon style={{ fontSize: 16, verticalAlign: 'middle' }} />
                        </span>
                      </a>
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </div> : null
          }
        </>;
      case BookingType.SUBSCRIPTION:   return <NewSubscription
      handleSelectionBack={() => setShowBookingTypeSelection(true)}
      position={topBarHeight}
    />;
      case BookingType.TEST_DRIVE: return <NewTestDriveBooking handleSelectionBack={() => setShowBookingTypeSelection(true)}/>;
      default:
        return <div className="flex main-center cross-center" style={{ height: 300 }}>
                <CircularProgress />
              </div>
    }
  }

  return (
    <>
      <TopBar
        height={((vehicleGroupsLoading || externalVehicleGroupsLoading) || vehiclesSearched) ? 50 : topBarHeight}
        logoSize={((vehicleGroupsLoading || externalVehicleGroupsLoading) || vehiclesSearched) ? 30 : logoSize}
      />
      {
        showBookingTypeSelection ? (
          <div className={styles.bookingTypeContainer}>
            <Grid container xs={12} >
              <Grid item xs={12} md={6} sm={12} lg={6} container justify="center" alignItems="center">
                <Box className={styles.boxMainCon}>
                  <Box className={styles.productTabLt}>
                    {Object.keys(bookingCategories).map(category => {
                      const bookingCategory = bookingCategories[category as BookingType];
                      return bookingCategory.enabled &&
                        <Button
                          className={`${classes.productTab} ${bookingType === category ? classes.active : ''}`}
                          onClick={() => {
                            resetStatesOnChangeBookingType()
                            setBookingType(category as BookingType)
                          }}
                        >
                          <Typography variant="h6" className={styles.productHeading}>
                            {bookingCategory.label}
                          </Typography>
                        </Button>
                    })}
                  </Box>
                  <Box m={2} mt={2} justifyItems={"center"}>
                    <Typography variant="h3" className={styles.productSubHeading}>
                      {bookingCategories[bookingType].subLabel}
                    </Typography>
                  </Box>
                  <Box className={styles.bookNowButtonBox}>
                    <Button
                      className={styles.btnBookNow} variant="contained" color="primary"
                      endIcon={<SendIcon></SendIcon>}
                      onClick={() => {
                        setBookingData({
                          ...bookingData,
                          pickupDateTime: "",
                          dropoffDateTime: "",
                          pickupServiceLocation: "",
                          bookingCategory: bookingType as BookingType
                        })
                        setShowBookingTypeSelection(false)
                      }}
                    >
                      {bookingCategories[bookingType].buttonText}
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} sm={12} lg={6}>
                <div className={styles.appBody}>
                  <PageModules setLocation={handleLocationSelection} showCarousel={false} bookingType={bookingType as BookingType} />
                </div>

              </Grid>
            </Grid>
          </div>
        ) : (
          renderBookingCategory(bookingType)
        )
      }

      {
        verificationDialog && (
          <Dialog
            open={verificationDialog}
            onClose={() => {
              setVerificationDialog(false);
              if (authState && authState.authUser?._id) {
                setCurrentStep(currentStep + 1);
              }
            }}
          >
            <div style={{ padding: 5 }}>
              <Authenticator onSignIn={() => setVerificationDialog(false)} />
            </div>
          </Dialog>
        )
      }
    </>
  );
};

export default NewBooking;
