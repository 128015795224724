import styles from "../index.module.css"
import React, { useEffect, useState } from 'react'
import Amplify, { Storage } from "aws-amplify";
import * as _ from "lodash";
import { IBookingCreateInput, ISubscriptionInfo, ISuggestedPricing, IVehicle } from '../../../../../reducers/bookings/types';
import { useSnackBar } from '../../../../SnackBarContext/SnackBarContext';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import { IFilter } from '../../../../FilterSection/FilterSection';
import { SnackBarVariant } from '../../../../SnackbarWrapper/SnackbarWrapper';
import { Box, Button, Card, CardActions, CardContent, CardMedia, createStyles, CssBaseline, Fab, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import { FiltersView } from '../../../../FiltersView/FiltersView';
import { DATE_TYPE, getUploadedImageByKey, getVehicleTypeIcon, toCurrency } from '../../../../utils';
import { IServiceLocation } from "../../../../../reducers/website/types";
import { getLocalizedDateFormat, getLocalizedTaxSyntex } from "../../../../../utils/localized.syntex";
import { capitalizeFirstLetter, sortSubscriptionVehicles } from "../../utils";
import theme from "../../../../Theme";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

const useStyles = makeStyles(() =>
  createStyles({
    media: {
      objectFit: "fill"
    },
  })
);
interface IProps {
  vehicles: IVehicle[];
  bookingData: IBookingCreateInput;
  onSubmit: (data: IBookingCreateInput, vehicle?: IVehicle) => void;
  criteriaData: any;
  locations: IServiceLocation[];
}

const CatalogueDetails: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const { country } = website.organisation.address;
  const organisation = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.organisation);
  const branchData = website.branches;
  const { locale, currency } = organisation;

  const [filters, setFilters] = useState<IFilter[]>([]);
  const [ownFilters, setOwnFilters] = useState<IFilter[]>([]);
  const [externalFilters, setExternalFilters] = useState<IFilter[]>([]);
  const [searchFilters, setSearchFilters] = useState<{
    [key: string]: string[];
  }>({});
  const [vehicleImages, setVehicleImages] = useState<any>([]);
  const [renderedSubscriptionVehicles, setRenderedSubscriptionVehicles] = useState<IVehicle[]>([])

  useEffect(() => {
    if (props.vehicles?.length > 0) {
      const activeSubscriptionVehicles: IVehicle[] = props.vehicles
        .map((vehicle) => {
          if (!vehicle || !vehicle.subscriptions.length) return null;

          const activeSubscriptions = vehicle.subscriptions.filter(
            (subscription) => subscription.subscription.isActive
          );

          return activeSubscriptions.length
            ? { ...vehicle, subscriptions: activeSubscriptions }
            : null;
        })
        .filter(Boolean) as IVehicle[];

      if (activeSubscriptionVehicles.length) {
        const bookedVehicleId =
          props.bookingData?.activeSubscription?.vehicle;

        const sortedVehicles = sortSubscriptionVehicles(
          activeSubscriptionVehicles,
          bookedVehicleId
        );

        getVehicleGroupImage(sortedVehicles);
        setRenderedSubscriptionVehicles(sortedVehicles);
      }
    }
  }, [props.vehicles, props.bookingData]);

  useEffect(() => {
    if (props.criteriaData) {
      const { consumerVehiclesCriteria } = props.criteriaData;
      if (consumerVehiclesCriteria) {
        const fetchedCriteria = formatCriteriaForFilter(consumerVehiclesCriteria);
        setOwnFilters(fetchedCriteria)
        const filtersData = mergeFiltersArray(fetchedCriteria, externalFilters)
        setFilters(filtersData)
      }
    }
  }, [props.criteriaData]);

  useEffect(() => {
    const availableVehicles = [];
    // tslint:disable-next-line: prefer-for-of
    const vehiclesArr = JSON.parse(JSON.stringify(props.vehicles))
    for (let i = 0; i < vehiclesArr.length; i++) {
      const vehicle: any = vehiclesArr[i];
      let available = true;
      // tslint:disable-next-line: prefer-for-of
      for (let k = 0; k < Object.keys(searchFilters).length; k++) {
        if (searchFilters[Object.keys(searchFilters)[k]].length) {
          if (!vehicle.hasOwnProperty(Object.keys(searchFilters)[k])) {
            available = false;
            break;
          }
          if (["year", "numberOfDoors", "numberOfSeats"].includes(Object.keys(searchFilters)[k])) {
            const filtersArr = searchFilters[Object.keys(searchFilters)[k]].map((item: string) => parseInt(item));
            if (!filtersArr.includes(vehicle[Object.keys(searchFilters)[k]])) {
              available = false;
              break;
            }
          } else if (Object.keys(searchFilters)[k] === "features") {
            let flag = true;
            if (vehicle[Object.keys(searchFilters)[k]].length) {
              vehicle[Object.keys(searchFilters)[k]].forEach((item: string) => {
                if (searchFilters[Object.keys(searchFilters)[k]].includes(item)) {
                  flag = false;
                }
              });
            }
            if (flag) {
              available = false;
              break;
            }
          } else {
            if (!searchFilters[Object.keys(searchFilters)[k]].includes(
              vehicle[Object.keys(searchFilters)[k]].toLowerCase())) {
              available = false;
              break;
            }
          }
        }
      }
      if (available) {
        availableVehicles.push(vehicle)
      }
    }
    const bookedVehicleId =
      props.bookingData?.activeSubscription?.vehicle;
    const sortedVehicles = sortSubscriptionVehicles(
      availableVehicles,
      bookedVehicleId
    );
    setRenderedSubscriptionVehicles(sortedVehicles)
  }, [searchFilters]);

  const formatCriteriaForFilter: any = (data: any) => {
    const filter = {} as any;
    for (let i = 0; i < data.length; i++) {
      if (data[i].values?.length) {
        let values = data[i].values.map((value: string) => value.toLowerCase())
        values = Array.from(new Set(values))
        filter[data[i].type] = {
          id: data[i].type,
          name: _.startCase(data[i].type),
          options: values.map((val: string) => {
            const formattedName = val.split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
            return {
              id: val,
              name: formattedName
            }
          })
        };
      }
    }
    return Object.values(filter);
  };

  const mergeFiltersArray = (arr1: IFilter[], arr2: IFilter[]) => {
    const data: IFilter[] = [];
    arr1.forEach(item => {
      if (item.options) {
        const idx: number = data.findIndex(ele => ele.id === item.id);
        if (idx < 0) {
          data.push(item);
        } else {
          const arrItem = { ...data[idx] };
          if (arrItem) {
            item.options.forEach((option) => {
              const itemIdx = arrItem.options.findIndex(ele => ele.id === option.id)
              if (itemIdx < 0) {
                arrItem.options.push(option)
              }
            })
            data[idx] = arrItem;
          }
        }
      }
    })
    arr2.forEach(item => {
      if (item.options) {
        const idx: number = data.findIndex(ele => ele.id === item.id);
        if (idx < 0) {
          data.push(item);
        } else {
          const arrItem = { ...data[idx] };
          if (arrItem) {
            item.options.forEach((option) => {
              const itemIdx = arrItem.options.findIndex(ele => ele.id === option.id)
              if (itemIdx < 0) {
                arrItem.options.push(option)
              }
            })
            data[idx] = arrItem;
          }
        }
      }
    })
    const yearIdx = data.findIndex(ele => ele.id === "year");
    if (yearIdx) {
      const yearItem = data[yearIdx];
      if (yearItem && yearItem.options) {
        yearItem.options = yearItem.options.sort((a, b) => parseInt(a.id) - parseInt(b.id));
      }
      data[yearIdx] = yearItem
    }
    return data;
  }

  const handleFilterUpdate = (filters: { [key: string]: string[] }) => {
    setSearchFilters({
      ...searchFilters,
      ...filters
    });
  };

  const getVehicleGroupImage = (vehicles: IVehicle[]) => {
    vehicles.map(async (vehicle: IVehicle) => {
      try {
        if (vehicle && vehicle.imageUrl) {
          const config = { contentType: "image/*", level: "public" };
          const file = await Storage.get(vehicle.imageUrl, config).then((result) => {
            return result;
          })
          const imgResponse = await fetch(file as string).then((res) => {
            return res;
          });
          if (!imgResponse.ok) {
            return
          }
          setVehicleImages([...vehicleImages, {
            vehicleId: vehicle.id,
            imageUrl: file
          }]);
        }
      } catch (error) {
        snackbar({
          message: "Invalid File Type. Allowed type: jpeg or png",
          variant: SnackBarVariant.ERROR
        });
      }
    });
  };

  const getLowestSubscriptionPricing = (vehicles: IVehicle[]): ISuggestedPricing => {
    const subscriptions: ISubscriptionInfo[] = [];

    // Collect all subscriptions
    vehicles.forEach(vehicle => {
      if (vehicle.subscriptions?.length) {
        subscriptions.push(...vehicle.subscriptions);
      }
    });

    // Filter active subscriptions and sort them by rateTypeName
    const activeSubscriptions = subscriptions.filter(subscription => subscription.subscription.isActive);

    // Gather pricing information
    let monthlyPricing: ISuggestedPricing = { subscriptionAmount: Infinity, rateTypeName: 'monthly' };
    let weeklyPricing: ISuggestedPricing = { subscriptionAmount: Infinity, rateTypeName: 'weekly' };

    activeSubscriptions.forEach(subscription => {
      subscription.pricings.forEach(pricing => {
        if (subscription.subscription.rateTypeName === 'monthly') {
          if (pricing.subscriptionAmount < monthlyPricing.subscriptionAmount) {
            monthlyPricing = {
              subscriptionAmount: pricing.subscriptionAmount,
              rateTypeName: 'monthly'
            };
          }
        } else if (subscription.subscription.rateTypeName === 'weekly') {
          if (pricing.subscriptionAmount < weeklyPricing.subscriptionAmount) {
            weeklyPricing = {
              subscriptionAmount: pricing.subscriptionAmount,
              rateTypeName: 'weekly'
            };
          }
        }
      });
    });

    // Return the monthly pricing if available; otherwise, return the weekly pricing
    return monthlyPricing.subscriptionAmount < Infinity ? monthlyPricing : weeklyPricing;
  };

  return (
    <Grid container>
      <Grid container item xs={12} spacing={2}>
        <CssBaseline />
        <Grid item xs={12}><Box mt={1}></Box></Grid>
        <Grid item xs={9} sm={8} md={9}>
          <Grid container spacing={2}>
            {
              renderedSubscriptionVehicles && renderedSubscriptionVehicles.length > 0 &&
              renderedSubscriptionVehicles.map((vehicle: IVehicle, index: number) => {
                vehicleImages.forEach((vehicleImage: any, index: number) => {
                  if (vehicleImage.imageUrl && vehicleImage.vehicleId === vehicle.id) {
                    vehicle.imageUrl = vehicleImage.imageUrl
                  }
                });
                if (vehicle && vehicle.subscriptions.length) {
                  const lowestPrice = getLowestSubscriptionPricing([vehicle])
                  if (lowestPrice.subscriptionAmount !== Infinity) {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        container
                      >
                        <Card
                          elevation={0}
                          className={styles.vehicle_catalogue}
                        >
                          {
                            props.bookingData?.activeSubscription?.vehicle === vehicle.id && (
                              <div className={styles.vehicle_catalogue_selected}>
                                <CheckCircleRoundedIcon fontSize="large" style={{ fill: "#4BB543" }} />
                              </div>
                            )
                          }
                          <CardContent className={styles.vehicle_catalogue_content}>
                            <Grid container item xs={12}>
                              <CssBaseline />
                              <Grid container item xs={12} className={styles.card_media_container}>
                                <CardMedia
                                  className={classes.media}
                                  component="img"
                                  height="200"
                                  style={{ borderRadius: 6 }}
                                  image={`${vehicle.imageUrl || getVehicleTypeIcon(capitalizeFirstLetter(vehicle?.bodyType || ""))}`}
                                  alt="Paella dish"
                                />
                              </Grid>
                              <Grid item xs={12}><Box mt={3}></Box></Grid>
                              <Grid item xs={12}>
                                <Typography style={{ fontWeight: 800, textTransform: "uppercase", fontSize: 18 }}>{`${vehicle.make} ${vehicle.model}`}</Typography>
                                <Box mt={1}></Box>
                                <Typography style={{ fontWeight: 500, fontSize: 14 }} variant="h5">Available From: {getLocalizedDateFormat(country, vehicle.availableFrom || "", DATE_TYPE.CONDENSED)}</Typography>
                              </Grid>
                              <Grid item xs={12}><Box mt={2}></Box></Grid>
                              <Grid item xs={12} container justify="space-between">
                                <Grid item xs={6}>
                                  <Button
                                    className={styles.next_step_btn}
                                    onClick={() => {
                                      props.onSubmit(props.bookingData, vehicle)
                                    }}
                                  >
                                    Select
                                  </Button>
                                </Grid>
                                <Grid item xs={6} container justify="flex-end">
                                  <Typography style={{ fontWeight: 800, fontSize: 20 }}>{toCurrency(lowestPrice.subscriptionAmount, currency, locale)}{"/"} {lowestPrice.rateTypeName === "monthly" ? "month" : "week"}  </Typography>
                                  <Grid item xs={12}></Grid>
                                  <Typography variant="h5" >+ {getLocalizedTaxSyntex(country)}</Typography>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}><Box mt={2}></Box></Grid>

                              <Grid item xs={12}><Box mt={1}></Box></Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )
                  }
                }
              })
            }
          </Grid>
        </Grid>
        <Grid item xs={3} sm={4} md={3}>
          <FiltersView
            filters={filters}
            onChange={handleFilterUpdate}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CatalogueDetails