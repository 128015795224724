import React, { useEffect, useState } from "react";
import { DateTime as d, Settings } from "luxon";
import styles from "./index.module.css";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { CircularProgress, TextField, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import withStyles from "@material-ui/core/styles/withStyles";
import { BookingStatus, IBooking } from "../../../../reducers/bookings/types";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { StyledTableCell, StyledTableRow } from "../../../StyledTable";
import TableBody from "@material-ui/core/TableBody";
import { useLazyQuery } from "@apollo/client";
import {
  GET_BOOKING_DASHBOARD,
} from "../../../../graphql/bookings/bookingsByCustomerID";
import { useHistory } from "react-router-dom";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { DATE_TYPE } from "../../../utils";
import { BookingStatusText } from "../../utils";
import dayjs from "dayjs";
import { VEHICLE_STATUS } from "../../Summary/const";

const StyledInput = withStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "8px 10px 9px 0 !important",
      borderColor: "#e9e9e9",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0,0,0,0.1)",
    },
  },
})(TextField);

const Bookings = () => {
  const history = useHistory();
  const [filters, setFilters] = useState<{
    bookingRef?: string;
    bookingStatuses?: string[];
  }>({ bookingRef: "", bookingStatuses: [] });
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const [bookings, setBookings] = useState<IBooking[]>([]);
  const [bookingState, setBookingState] = useState("All")
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const { country } = website.organisation.address;
  const branchData = website.branches;
  const DTablet = useMediaQuery('(max-width:768px)')
  const [
    getBookingsByCustomerId,
    { loading: bookingsLoading, data: bookingsData, error },
  ] = useLazyQuery(GET_BOOKING_DASHBOARD, {
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (user) {
      getBookingsByCustomerId({
        variables: {
          customerId: user.businesscustomers
            ? user.businesscustomers?.id
            : user.customerId,
        },
      });
    }
  }, [user]);

  useEffect(() => {
    if (bookingsData && bookingsData.getConsumerBookingsDashboard) {
      const bookingData = bookingsData.getConsumerBookingsDashboard;
      const _bookings: IBooking[] = [...bookingData.currentBookings, ...bookingData.upcomingBookings, ...bookingData.pastBookings]
      const bookingBranchId = _bookings[0]?.branchId;
      const selectedBranch = branchData.find((branch) => branch.id === bookingBranchId);
      if (selectedBranch) {
        Settings.defaultZone = selectedBranch.timeZone || "Europe/London";
      }
      setBookings(
        _bookings.filter(b => b.status !== BookingStatus.DRAFT).sort((a:IBooking, b:IBooking) => {return (dayjs(b.pickupDateTime)).diff(dayjs(a.pickupDateTime))})
      );
    }
  }, [bookingsData]);

  useEffect(() => {
    if (filters.bookingRef) {
      const bookingRef = filters.bookingRef;
      const bookingData = bookingsData?.getConsumerBookingsDashboard;
      let _bookings: IBooking[] = [...bookingData?.currentBookings, ...bookingData?.upcomingBookings, ...bookingData?.pastBookings]
      if(bookingState === "Current"){
        _bookings = ([...bookingData?.currentBookings].sort((a:IBooking, b:IBooking) => {return (dayjs(b.pickupDateTime)).diff(dayjs(a.pickupDateTime))}));
      }
      if(bookingState === "Upcoming"){
        _bookings = ([...bookingData?.upcomingBookings].sort((a:IBooking, b:IBooking) => {return (dayjs(b.pickupDateTime)).diff(dayjs(a.pickupDateTime))}))
      }
      if(bookingState === "Past"){
        _bookings = ([...bookingData?.pastBookings].sort((a:IBooking, b:IBooking) => {return (dayjs(b.pickupDateTime)).diff(dayjs(a.pickupDateTime))}))
      }
      if(bookingState === "All"){
        const { currentBookings, upcomingBookings, pastBookings } = bookingData;
        const allBookings = [...currentBookings, ...upcomingBookings, ...pastBookings];
        _bookings = (allBookings.sort((a:IBooking, b:IBooking) => {return (dayjs(b.pickupDateTime)).diff(dayjs(a.pickupDateTime))}));
      }
      let filteredBookings = _bookings;
      filteredBookings = filteredBookings.filter((booking) => {
        const referenceNumber = booking.referenceNumber.toLowerCase();
        return referenceNumber.includes(bookingRef.toLowerCase());
      });
      setBookings(filteredBookings.sort((a:IBooking, b:IBooking) => {return (dayjs(b.pickupDateTime)).diff(dayjs(a.pickupDateTime))}));
    }
    else if(!filters.bookingRef && bookingsData && bookingsData.getConsumerBookingsDashboard){
      const bookingData = bookingsData?.getConsumerBookingsDashboard;
      if(bookingState === "Current"){
        setBookings([...bookingData?.currentBookings].sort((a:IBooking, b:IBooking) => {return (dayjs(b.pickupDateTime)).diff(dayjs(a.pickupDateTime))}));
      }
      if(bookingState === "Upcoming"){
        setBookings([...bookingData?.upcomingBookings].sort((a:IBooking, b:IBooking) => {return (dayjs(b.pickupDateTime)).diff(dayjs(a.pickupDateTime))}))
      }
      if(bookingState === "Past"){
        setBookings([...bookingData?.pastBookings].sort((a:IBooking, b:IBooking) => {return (dayjs(b.pickupDateTime)).diff(dayjs(a.pickupDateTime))}))
      }
      if(bookingState === "All"){
        const { currentBookings, upcomingBookings, pastBookings } = bookingData;
        const allBookings = [...currentBookings, ...upcomingBookings, ...pastBookings];
        setBookings(allBookings.sort((a:IBooking, b:IBooking) => {return (dayjs(b.pickupDateTime)).diff(dayjs(a.pickupDateTime))}));
      }
    }
  }, [filters.bookingRef]);

  function getVehicleDisplayText(bookingStatus:any, vehicle:any, vehicleStatus: string) {    
    if ([BookingStatus.IN_PROGRESS, BookingStatus.COMPLETED].includes(bookingStatus) && vehicleStatus !== VEHICLE_STATUS.CONFIRMED) {
        return vehicle.isGhostVehicle 
            ? <span style={{ color: "red" }}>To be assigned</span> 
            : vehicle.licencePlate;
    }
    return "-";
}
  
  const getVehiclesString = (booking: IBooking) => {
    const _vehicles = booking.currentBookingSchedules.map(
      (bs) =>
        getVehicleDisplayText(booking.status, bs.vehicle, bs.vehicleStatus)
      );
    return ( 
      <>
        {_vehicles.slice(0, 1)}{" "}
        {_vehicles.length > 1 && (
          <span className="opacity40">...{_vehicles.length - 1} More</span>
        )}
      </>
    );
  };

  enum EmptyLabel {
    CONFIRMED = "No Upcoming Bookings Found!",
    IN_PROGRESS = "No In-progress Bookings Found!",
    COMPLETED = "No Past Bookings Found!",
    CANCELLED = "No Past Bookings Found!",
    QUOTE = "No Upcoming Bookings Found!",
    CONFIRMATION_IN_PROCESS = "No Upcoming Bookings Found!",
  }

  return (
    <div className={styles.column}>
      <div className={DTablet ? "flex fill col-flex" : "flex fill cross-center"}>
        <div className="flex">
          <Typography variant="h2" className="semi-bold">
            {`${getLocalizedBookingSyntex(country)}s`}
          </Typography>
          {bookingsLoading && (
            <CircularProgress
              size={20}
              thickness={5}
              style={{ marginLeft: 10 }}
            />
          )}
        </div>
        <div className={styles.filters} style={{marginTop: DTablet ? '5px' : '0', marginLeft: DTablet ? '0': '5px'}}>
          <div className="margin-right">
            <StyledInput
              size="small"
              value={filters.bookingRef}
              disabled={bookingsLoading}
              placeholder={`Search ${getLocalizedBookingSyntex(country).toLowerCase()} ref`}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, bookingRef: e.target.value }))
              }
              InputProps={{
                style: {
                  padding: "0 0 0 8px",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ opacity: 0.4 }} fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <button
            className={`${styles.button} ${!filters.bookingStatuses && styles.selected}`}
            onClick={() => {
              setFilters((prev) => ({ ...prev, bookingStatuses: [] }));
              setBookingState("All")
              if (bookingsData && bookingsData.getConsumerBookingsDashboard) {
                const { currentBookings, upcomingBookings, pastBookings } = bookingsData.getConsumerBookingsDashboard;
                const allBookings = [...currentBookings, ...upcomingBookings, ...pastBookings];
                setBookings(allBookings.sort((a:IBooking, b:IBooking) => {return (dayjs(b.pickupDateTime)).diff(dayjs(a.pickupDateTime))}));
              }
            }}
          >
            All
          </button>
          <button
            className={`${styles.button} ${styles.current} ${filters.bookingStatuses?.includes(BookingStatus.IN_PROGRESS) &&
              styles.selected
              }`}
            onClick={() => {
              setFilters((prev) => ({
                ...prev,
                bookingStatuses: [BookingStatus.IN_PROGRESS],
              }));
              setBookingState("Current")
              if (bookingsData && bookingsData.getConsumerBookingsDashboard) {
                const currentBookings = bookingsData.getConsumerBookingsDashboard.currentBookings;
                setBookings([...currentBookings].sort((a:IBooking, b:IBooking) => {return (dayjs(b.pickupDateTime)).diff(dayjs(a.pickupDateTime))}));
              }
            }}

          >
            Current
          </button>
          <button
            className={`${styles.button} ${styles.upcoming} ${filters.bookingStatuses?.includes(BookingStatus.CONFIRMED) &&
              styles.selected
              }`}
            onClick={() => {
              setFilters((prev) => ({
                ...prev,
                bookingStatuses: [BookingStatus.CONFIRMED],
              }))
              setBookingState("Upcoming")
              setBookings([...bookingsData?.getConsumerBookingsDashboard?.upcomingBookings].sort((a:IBooking, b:IBooking) => {return (dayjs(b.pickupDateTime)).diff(dayjs(a.pickupDateTime))}))
            }
            }
          >
            Upcoming
          </button>
          <button
            className={`${styles.button} ${styles.past} ${(filters.bookingStatuses?.includes(BookingStatus.CANCELLED) &&
              styles.selected) ||
              (filters.bookingStatuses?.includes(BookingStatus.COMPLETED) &&
                styles.selected)
              }`}
            onClick={() => {
              setFilters((prev) => ({
                ...prev,
                bookingStatuses: [BookingStatus.CANCELLED, BookingStatus.COMPLETED],
              }))
              setBookingState("Past")
              setBookings([...bookingsData?.getConsumerBookingsDashboard?.pastBookings].sort((a:IBooking, b:IBooking) => {return (dayjs(b.pickupDateTime)).diff(dayjs(a.pickupDateTime))}))
            }
            }
          >
            Past
          </button>
        </div>
      </div>
      {!bookingsLoading && (
        <div className="margin-top padding-top">
          <TableContainer style={{ maxHeight: "100%" }} className="row-hover">
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{getLocalizedBookingSyntex(country)} Ref</StyledTableCell>
                  <StyledTableCell>
                    <div>Status</div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div>Start Date</div>
                  </StyledTableCell>
                  <StyledTableCell>End Date</StyledTableCell>
                  <StyledTableCell>Vehicles</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bookings.length ? (
                  bookings.map((booking, index) => (
                    <StyledTableRow
                      key={index}
                      onClick={() =>
                        history.push(`/account/bookings/${booking.id}`)
                      }
                    >
                      <StyledTableCell>
                        {booking.referenceNumber}
                      </StyledTableCell>
                      <StyledTableCell>
                        <span className={`${styles.chip} ${styles.current}`}>
                          {BookingStatusText[booking.status]}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell>
                        {
                          getLocalizedDateFormat(country, booking.pickupDateTime, DATE_TYPE.EXPANDED,booking.branch?.timeZone)
                        }
                      </StyledTableCell>
                      <StyledTableCell>
                        {booking.dropoffDateTime
                          ? getLocalizedDateFormat(country, booking.dropoffDateTime, DATE_TYPE.EXPANDED,booking.branch?.timeZone)
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell>
                        {booking && getVehiclesString(booking)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableCell colSpan={20}>
                    <Typography style={{ textAlign: "center", opacity: 0.5 }}> "Sorry, no matching records found!"</Typography>
                  </StyledTableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default Bookings;
