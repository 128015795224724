import React, { useEffect, useRef, useState } from 'react'
import CatalogueDetails from './CatalogueDetails';
import VehicleDetail from './VehicleDetail';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { DateTime as d, Settings } from "luxon";
import { BOOKING_SOURCE, BookingType, IAddonRequirementInput, IBooking, IBookingAddonRequirement, IBookingCreateInput, ICustomerInfoInput, IOneWayRentalFee, ISubscriptionArgs, IVehicle } from '../../../../reducers/bookings/types';
import { GET_AVAILABLE_SUBSCRIPTION_VEHICLES } from '../../../../graphql/bookings/getAvailableVehiclesForSubscriptionsQuery';
import { Box, CircularProgress, CssBaseline, Dialog, Grid, IconButton, makeStyles, Step, StepButton, Stepper, Theme, Tooltip } from '@material-ui/core';
import Container from "@material-ui/core/Container";
import { useDispatch, useSelector } from 'react-redux';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { IAppState } from '../../../../store';
import { GET_VEHICLE_GROUP_CRITERIA, GET_VEHICLES_GROUP_CRITERIA } from '../../../../graphql/vehiclePriceGroup/getVehicleGroupCriteria';
import { initialCustomerInfo, initialValues } from '../NewBooking';
import { IBranch, IServiceLocation } from '../../../../reducers/website/types';
import { CREATE_BOOKING } from '../../../../graphql/bookings/createBookingMutation';
import { useHistory, useLocation } from 'react-router-dom';
import { SnackBarVariant } from '../../../SnackbarWrapper/SnackbarWrapper';
import { formatGraphQLErrorMessage, rateTypeName } from '../../../utils';
import { useSnackBar } from '../../../SnackBarContext/SnackBarContext';
import { RATE_TYPES } from '../../utils';
import { SubscriptionFlowStage } from '../utils';
import Authenticator from "../../../views/Auth";
import { updateBooking as updateBookingAction } from '../../../../actions/bookings/actions';
import PersonalDetails from './PersonalDetails/PersonalDetails';
import { IDriverRow } from './PersonalDetails/CustomerView/AddNewDriver';
import Verification from './Verification/Verification';
import { GET_BOOKING } from '../../../../graphql/bookings/getBookingQuery';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
  },
  stepper: {
    color: theme.palette.primary.light,
    "& .MuiStepLabel-label": {
      color: theme.palette.primary.light
    }
  },
  backToSelectionScreen: {
    position: "absolute",
    left: 0,
    top: -40
  },
}));

const steps = [
  "Vehicle Catalogues",
  "Vehicle Details",
  "Personal Details",
  "Verification"
]

const stepsCompletedMapInitial = [false, false, false, false, false];

interface IProps {
  handleSelectionBack: () => void;
  position: number;
}

const NewSubscription: React.FC<IProps> = (props) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const snackbar = useSnackBar();

  const [activeStep, setActiveStep] = useState<number>(0)
  const [vehicles, setVehicles] = useState<IVehicle[]>([])
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const organisation = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.organisation);
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const authState = useSelector((state: IAppState) => state.authReducer);

  const branchData = website.branches;

  const [bookingData, setBookingData] = useState<IBookingCreateInput>(
    initialValues
  );
  const [selectedVehicle, setSelectedVehicle] = useState<IVehicle>()
  const [locations, setLocations] = useState<IServiceLocation[]>([])
  const [verificationDialog, setVerificationDialog] = useState(false);
  const [stepsCompletedMap, setStepsCompletedMap] = useState<boolean[]>(stepsCompletedMapInitial);
  const [customerInfo, setCustomerInfo] = useState<ICustomerInfoInput>(initialCustomerInfo);
  const [bookingCreationFailed, setBookingCreationFailed] = useState<boolean>(false);
  const [bookingDrivers, setBookingDrivers] = useState<IDriverRow[]>([])
  const [oneWayRentalFee, setOneWayRentalFee] = useState<IOneWayRentalFee>();
  const [isLastStepPersonalDetails, setIsLastStepPersonalDetails] = useState<boolean>(false)
  const [isLastStepVerification, setIsLastStepVerification] = useState<boolean>(false)
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  const driversIdRef = useRef<string[]>([]);

  const [
    loadSubscriptionVehicles,
    { loading: subscriptionVehiclesLoading }
  ] = useLazyQuery(GET_AVAILABLE_SUBSCRIPTION_VEHICLES, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (bookingData && bookingData.id) {
        const rateTypeName = bookingData.activeSubscription.rateTypeName
        const updatedVehicles = data.getConsumerSubscriptionCatalogue.map((vehicle: IVehicle) => ({
          ...vehicle,
          subscriptions: vehicle.subscriptions.filter(
            subscription => subscription.subscription.rateTypeName === rateTypeName
          )
        }));
        setVehicles(updatedVehicles)
      } else {
        setVehicles(data.getConsumerSubscriptionCatalogue)
      }
    }
  });

  const [loadVehicleCriteria, { loading: vehicleCriteriaLoading, data: criteriaData }] = useLazyQuery(
    GET_VEHICLES_GROUP_CRITERIA,
    {
      fetchPolicy: "network-only"
    }
  );

  const [createBookingMutation, { data: bookingCreateData, loading: bookingCreating }] = useMutation(
    CREATE_BOOKING,
    {
      onCompleted: (data) => {
        dispatch(updateBookingAction(data.consumerCreateBooking));
        history.push(`/quote?id=${data.consumerCreateBooking.id}`)
        // if (newAddedDriversId.length || driversIdRef.current.length) {
        //   addDriversToBooking({
        //     variables: {
        //       bookingId: data.consumerCreateBooking.id,
        //       drivers: newAddedDriversId.length ? newAddedDriversId : driversIdRef.current
        //     }
        //   })
        // }
        snackbar({
          message: "Quote created",
          variant: SnackBarVariant.SUCCESS
        });
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        // setBookingCreationFailed(true);
      }
    }
  );

  const [
    loadBooking,
    { loading: bookingLoading, data: bookingInfoData }
  ] = useLazyQuery(GET_BOOKING, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (!data.consumerGetBooking) {
        history.goBack()
      }
      if (data.consumerGetBooking && data.consumerGetBooking.status) {
        // setBookingStatus(data.consumerGetBooking.status)
      }
      if (data.consumerGetBooking && data.consumerGetBooking.approvedDrivers.length) {
        setBookingDrivers(data.consumerGetBooking.approvedDrivers)
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (website?.subscriptionSettings?.creditCheckEnabled ||
      website?.subscriptionSettings?.dvlaVerificationEnabled ||
      website?.subscriptionSettings?.idvEnabled) {
      setIsLastStepVerification(true)
    } else {
      setIsLastStepPersonalDetails(true)
    }
  }, [website])

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const bookingId = params.get("booking");
      if (bookingId) {
        setIsUpdate(true);
        return loadBooking({
          variables: {
            bookingId: bookingId
          }
        });
      } else {
        loadSubscriptionVehicles();
        loadVehicleCriteria();
      }
    } else {
      loadSubscriptionVehicles()
      loadVehicleCriteria()
    }
  }, [location, website.organisation.allowSubscriptionBookings]);

  useEffect(() => {
    if (bookingInfoData && bookingInfoData.consumerGetBooking) {
      const _booking = bookingInfoData.consumerGetBooking;
      loadVehicleCriteria({
        variables: { branchId: _booking.branchId }
      })
      formatAndSetBookingData(_booking);
      loadSubscriptionVehicles()
    }
  }, [bookingInfoData]);

  const handleStep = (index: number) => {
    if (index < activeStep) {
      setActiveStep(index);
      const tempStepsCompMap = [...stepsCompletedMap];
      for (var i = index + 1; i < stepsCompletedMap.length; i++) {
        tempStepsCompMap[i] = false
      }
      setStepsCompletedMap(tempStepsCompMap);
    }
  }

  const formatAndSetBookingData = (booking: IBooking) => {
    const addonRequirements: IAddonRequirementInput[] = booking.addonRequirements.map(
      (addonR: IBookingAddonRequirement) => {
        return {
          addon: addonR.addon.id,
          quantity: addonR.quantity,
          name: addonR.name,
          hasFixedRate: addonR.hasFixedRate,
          rate: addonR.rate,
          displayName: addonR.displayName,
          tax: {
            title: addonR.tax && addonR.tax.title ? addonR.tax.title : "",
            value: addonR.tax && addonR.tax.value ? addonR.tax.value : 0
          }
        };
      }
    );
    const bookingObj: IBookingCreateInput = {
      id: booking.id,
      pickupServiceLocation: booking.pickupServiceLocation?.id,
      dropoffServiceLocation: booking.dropoffServiceLocation?.id,
      pickupOtherLocation: booking.pickupOtherLocation,
      dropoffOtherLocation: booking.dropoffOtherLocation,
      pickupDateTime: booking.pickupDateTime,
      dropoffDateTime: booking.dropoffDateTime,
      longTermBooking: booking.longTermBooking,
      flightNumber: booking.flightNumber,
      vehicleGroups: [],
      insuranceRate: booking.insuranceRate,
      excess: booking.excess,
      insurancePolicy: booking.insurancePolicy?.id,
      addonRequirements,
      customer: booking.customer?.id,
      customerType: booking.customerType,
      approvedDrivers: booking.approvedDrivers,
      rateTypeName: booking.rateTypeName,
      rateTypeDuration: booking.rateTypeDuration,
      billingCycleName: booking.billingCycleName,
      billingCycleDuration: booking.billingCycleDuration,
      businessCustomer: booking.businessCustomer?.id,
      authorizedSignatory: booking.authorizedSignatory,
      pcoNumber: booking.pcoNumber,
      preBookingQuestions: booking.preBookingQuestions,
      deliveryQuestions: booking.deliveryQuestions,
      source: booking.source ? booking.source : BOOKING_SOURCE.B2B2C,
      locationSurcharges: booking.locationSurcharges,
      smartCarVehicle: booking.smartCarVehicle,
      isCarShare: booking.isCarShare || false,
      status: booking.status,
      poNumber: booking.poNumber,
      isRecurringBilling: booking.isRecurringBilling,
      billingFrequency: booking.billingFrequency,
      branchId: booking.branchId,
      isSubscription: booking.isSubscription || false,
      activeSubscriptionExpanded: booking.activeSubscription,
      bookingCategory: booking.bookingCategory,
    };
    if (booking.activeSubscription) {
      bookingObj.activeSubscription = {
        startDate: booking.activeSubscription.startDate,
        endDate: booking.activeSubscription.endDate,
        subscription: booking.activeSubscription.subscription.id,
        vehicle: booking.activeSubscription.vehicle.id || "",
        duration: booking.activeSubscription.duration,
        subscriptionAmount: booking.activeSubscription.subscriptionAmount,
        enrollmentAmount: booking.activeSubscription.enrollmentAmount,
        unlimitedMileage: booking.activeSubscription.unlimitedMileage ? true : false,
        mileageLimit: booking.activeSubscription.mileageLimit || 0,
        pricePerExtraMile: booking.activeSubscription.pricePerExtraMile || 0,
        rateTypeName: booking.activeSubscription.subscription.rateTypeName
      }
    }
    setCustomerInfo({ ...customerInfo, preBookingQuestions: booking.preBookingQuestions })
    setBookingData(bookingObj);
  };

  const nextStep = (data: IBookingCreateInput, vehicle?: IVehicle, updateOnly?: boolean) => {
    if (vehicle) {
      data.branchId = vehicle?.branchId
      setSelectedVehicle(vehicle)
    }
    driversIdRef.current = data.approvedDrivers
    delete data.defaultBillingCycle;
    const tempStepsCompMap = [...stepsCompletedMap];
    if (data) {
      setBookingData(data);
    }
    if (!updateOnly) {
      if (activeStep <= Object.keys(stepMapper).length - 1) {
        if (activeStep === SubscriptionFlowStage.VEHICLE_DETAILS && !authState.user?.id) {
          setVerificationDialog(true)
          setActiveStep(activeStep => activeStep + 1)
        }
        else if (
          (activeStep === SubscriptionFlowStage.PERSONAL_DETAILS && isLastStepPersonalDetails) ||
          (activeStep === SubscriptionFlowStage.VERIFICATION && isLastStepVerification)
        ) {
          const booking = {
            ...bookingData,
            ...data,
          };
          booking.vehicleGroups = booking.vehicleGroups.filter((item) => item.vehicleIds.length > 0);
          createBooking({ ...booking, ...(booking.id && { bookingId: booking.id }) });
        } else {
          tempStepsCompMap[activeStep] = true;
          setStepsCompletedMap(tempStepsCompMap);
          setActiveStep(activeStep + 1);
        }
      }
    }
    // setActiveStep(prev => prev + 1)
  }

  const prevStep = () => {
    setActiveStep(prev => prev - 1)
  }

  const onSelectAvailabilityDate = (date: string) => {
    setBookingData({
      ...bookingData,
      pickupDateTime: date
    })
  }

  const createBooking = (bookingInput: IBookingCreateInput) => {
    const addonRequirements = bookingInput.addonRequirements.map(
      (addonReq: IAddonRequirementInput) => {
        return {
          addon: addonReq.addon,
          hasFixedRate: addonReq.hasFixedRate,
          name: addonReq.name,
          displayName: addonReq.displayName,
          quantity: addonReq.quantity,
          rate: addonReq.rate,
          tax: {
            title: addonReq.tax && addonReq.tax.title ? addonReq.tax.title : "",
            value: addonReq.tax && addonReq.tax.value ? addonReq.tax.value : 0
          }
        };
      }
    );
    const booking: any = {
      ...(bookingInput.bookingId && { bookingId: bookingInput.bookingId }),
      pickupServiceLocation: bookingInput.pickupServiceLocation,
      pickupDateTime: d.fromISO(bookingInput.pickupDateTime).toUTC().toISO(),
      dropoffServiceLocation: bookingInput.dropoffServiceLocation,
      dropoffDateTime: bookingInput.dropoffDateTime
        ? d.fromISO(bookingInput.dropoffDateTime).toUTC().toISO()
        : bookingInput.dropoffDateTime,
      vehicleGroups: bookingInput.vehicleGroups,
      insuranceRate: bookingInput.insuranceRate,
      excess: bookingInput.excess,
      insurancePolicy: bookingInput.insurancePolicy,
      addonRequirements,
      customer: bookingInput.customer ? bookingInput.customer : authState.user?.customerId,
      businessCustomer: bookingInput.businessCustomer ? bookingInput.businessCustomer : authState.user?.businesscustomers?.id,
      rateTypeName: bookingInput.rateTypeName,
      rateTypeDuration: bookingInput.rateTypeDuration,
      preBookingQuestions: bookingInput.preBookingQuestions,
      customerNote: bookingInput.customerNote,
      smartCarVehicle: bookingInput.smartCarVehicle,
      isCarShare: bookingInput.isCarShare,
      locationSurcharges: bookingInput.locationSurcharges,
      createB2B2CUser: true,
      isRecurringBilling: bookingInput.isRecurringBilling,
      billingFrequency: bookingInput.billingFrequency,
      isSubscription: bookingInput.isSubscription || false,
      isCoi: bookingInput.isCoi || false,
      pickupOtherLocation: bookingInput.pickupOtherLocation,
      dropoffOtherLocation: bookingInput.dropoffOtherLocation,
      bookingCategory: BookingType.SUBSCRIPTION,
      deliveryAddress: bookingInput.deliveryAddress

    };
    if (bookingInput.costCenter) {
      booking.costCenter = bookingInput.costCenter;
      if (bookingInput.projectId) {
        booking.projectId = bookingInput.projectId;
      }
    }
    if (bookingInput.isSubscription) {
      booking.activeSubscription = bookingInput.activeSubscription
      booking.locationSurcharges = []
    }
    // setBookingCreationFailed(false);
    createBookingMutation({
      variables: {
        branchId: bookingData.branchId,
        booking
      }
    });
  };

  const onSelectSubscription = (selectedSubscription: ISubscriptionArgs, vehicle: IVehicle, startDate: string) => {
    const booking = { ...bookingData }

    if (selectedSubscription && selectedSubscription?.subscription && selectedSubscription.subscription.id) {
      const weeklySubscription = selectedSubscription.subscription.rateTypeName === RATE_TYPES.WEEKLY ? true : false
      const durationKey = selectedSubscription.subscription.rateTypeName === "monthly" ? "month" : "week";

      const currentBranch = website.branches.find(branch => branch.id === bookingData.branchId)
      const vehicleCurrentLocation = vehicle.currentServiceLocation?.isDisabled ? null : vehicle.currentServiceLocation
      const currentLocation = vehicleCurrentLocation ? vehicleCurrentLocation : currentBranch?.activeLocations[0]
      const vehiclePreparationTime = currentBranch?.vehiclePreparationTime
      const availableTimeMinutes = d.fromISO(startDate || "").minute
      const roundToMultipleOf15 =  Math.ceil(availableTimeMinutes / 15) * 15;

      startDate = vehiclePreparationTime ? d.fromISO(startDate).setZone(currentBranch?.timeZone || "Europe/London").set({minute: roundToMultipleOf15 + vehiclePreparationTime + 15, millisecond: 0}).toUTC().toISO() : d.fromISO(startDate).setZone(currentBranch?.timeZone || "Europe/London").set({minute: roundToMultipleOf15 + 15, millisecond: 0}).toUTC().toISO()
      const endDate = d.fromISO(startDate || "").setZone(currentBranch?.timeZone || "Europe/London").plus({ [durationKey]: selectedSubscription?.pricing?.duration }).toUTC().toISO()
      
      const activeSubscription = {
        duration: selectedSubscription?.pricing?.duration,
        endDate,
        enrollmentAmount: selectedSubscription?.pricing?.enrollmentAmount,
        subscriptionAmount: selectedSubscription?.pricing?.subscriptionAmount,
        startDate: startDate,
        subscription: selectedSubscription?.subscription?.id,
        vehicle: vehicle?.id || "",
        unlimitedMileage: selectedSubscription?.pricing?.unlimitedMileage,
        mileageLimit: selectedSubscription?.pricing?.mileage,
        pricePerExtraMile: selectedSubscription?.pricing?.pricePerExtraMile,
        swapsAllowed: selectedSubscription?.pricing?.swapsAllowed,
        deposit: selectedSubscription.deposit,
        rateTypeName: selectedSubscription?.subscription?.rateTypeName,
      }
      setBookingData({
        ...booking,
        vehicleGroups: [],
        isSubscription: true,
        activeSubscription,
        pickupServiceLocation: currentLocation?.id || "",
        dropoffServiceLocation: currentLocation?.id || "",
        pickupDateTime: startDate,
        dropoffDateTime: endDate,
        billingCycleDuration: weeklySubscription ? 10080 : 43200,
        billingCycleName: weeklySubscription ? RATE_TYPES.WEEKLY : RATE_TYPES.MONTHLY,
        rateTypeDuration: weeklySubscription ? 10080 : 43200,
        rateTypeName: weeklySubscription ? RATE_TYPES.WEEKLY : RATE_TYPES.MONTHLY,
        billingFrequency: 1,
        isRecurringBilling: true,
        locationSurcharges: [],
        defaultBillingCycle: false
      })
    }
  }

  const stepMapper: any = {
    0: (
      <CatalogueDetails
        vehicles={vehicles}
        bookingData={bookingData}
        criteriaData={criteriaData}
        locations={locations}
        onSubmit={nextStep}
      />
    ),
    1: (
      <VehicleDetail
        bookingData={bookingData}
        setBookingData={setBookingData}
        selectedVehicle={selectedVehicle}
        onSubmit={nextStep}
        onSelectAvailabilityDate={onSelectAvailabilityDate}
        onSelectSubscription={onSelectSubscription}
      />
    ),
    2: (
      <PersonalDetails
        bookingData={bookingData}
        onSubmit={nextStep}
        onPrevious={prevStep}
        customerInfo={customerInfo}
        bookingCreationFailed={bookingCreationFailed}
        bookingDrivers={bookingDrivers}
        oneWayRentalFee={oneWayRentalFee}
        updateBookingDrivers={(drivers) => {
          if (drivers.length) {
            const driversId = drivers
              .filter((driver) => typeof driver.id === 'string')
              .map((driver) => driver.id as string);

            if (driversId.length) {
              if (bookingData?.approvedDrivers?.length) {
                const newDriversId = driversId.filter((id) => !bookingData.approvedDrivers.some((approvedDriver: IDriverRow) => approvedDriver.id === id));
                // setNewAddedDriversId(newDriversId);
                // driversIdRef.current = newDriversId;
              } else {
                // setNewAddedDriversId(driversId);
                // driversIdRef.current = driversId;
              }
            }
            setBookingDrivers(drivers);
          }
        }}
        isLastStepPersonalDetails={isLastStepPersonalDetails}
      />
    ),
    3: (
      <Verification
        onSubmit={nextStep}
        customerInfo={customerInfo}
        bookingData={bookingData}
      />
    )
  };

  const getStepIcon = (step: string, activeStep: number) => {
    switch (step) {
      case "Vehicle Catalogues":
        return <LocalTaxiIcon style={{ color: activeStep === 0 ? "rgba(255, 199, 0, 1)" : "rgba(0,0,0,0.5)", fontSize: 36 }} />
      case "Vehicle Details":
        return <LocalTaxiIcon style={{ color: activeStep === 1 ? "rgba(255, 199, 0, 1)" : "rgba(0,0,0,0.5)", fontSize: 36 }} />
      case "Personal Details":
        return <AccountCircleIcon style={{ color: activeStep === 2 ? "rgba(255, 199, 0, 1)" : "rgba(0,0,0,0.5)", fontSize: 36 }} />
      case "Verification":
        return <AccountCircleIcon style={{ color: activeStep === 3 ? "rgba(255, 199, 0, 1)" : "rgba(0,0,0,0.5)", fontSize: 36 }} />
    }
  }

  return (
    <Container style={{ marginTop: props.position + 35 }}>
      <Grid container spacing={2}>
        <CssBaseline />
        <Grid item xs={12}>
          <Box style={{ width: "100%", margin: "0 auto", position: "relative" }}>
            {
              (website.bookingTypes.length > 1 && (
                <div className={classes.backToSelectionScreen}>
                  <Tooltip title="Go back to booking categories selection.">
                    <IconButton
                      onClick={props.handleSelectionBack}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              )
              )
            }
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => {
                if (
                  (label === "Verification" && !isLastStepVerification)) {
                  return;
                }
                const stepProps = {};
                return <Step completed={stepsCompletedMap[index]} key={label} {...stepProps}>
                  <StepButton
                    onClick={() => handleStep(index)}
                    completed={stepsCompletedMap[index]}
                    className={stepsCompletedMap[index] ? classes.stepper : ""}
                  >
                    {label}
                  </StepButton>
                </Step>
              })}
            </Stepper>
          </Box>
        </Grid>
        {
          (subscriptionVehiclesLoading || vehicleCriteriaLoading || bookingLoading) ?
            (
              <Grid container justify="center" alignItems="center" style={{ height: "50vh" }} item xs={12}>
                <CircularProgress />
              </Grid>
            )
            : (
              <Grid container item xs={12}>
                <Grid item xs={12}><Box mt={2}></Box></Grid>
                <Box style={{ width: "100%" }}>
                  {bookingCreating ? <Grid item xs={12} container justify='center' alignItems='center' style={{ height: 400 }} > <CircularProgress /></Grid> : stepMapper[activeStep]}
                </Box>
              </Grid>
            )
        }

      </Grid >
      {
        verificationDialog && (
          <Dialog
            open={verificationDialog}
            onClose={() => {
              if (authState && authState.authUser?._id) {
                setActiveStep(prev => prev + 1)
              }
            }}
          >
            <div style={{ padding: 5 }}>
              <Authenticator onSignIn={() => setVerificationDialog(false)} />
            </div>
          </Dialog>
        )
      }
    </Container>
  )
}

export default NewSubscription
