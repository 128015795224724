import { Container, Grid, IconButton, Tooltip } from "@material-ui/core";
import { AvailableTestDriveVehicles } from "./WelcomePage/AvailableVehicleGroups";
import CarouselModule from "../../../CarouselModule/CarouselModule";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { TestDriveBookingSteps } from "../../../../reducers/testDriveBooking/types";
import { BranchSelection } from "./BranchSelection";
import { VehicleSelection } from "./VehicleSelection";
import { VehicleConfirmation } from "./VehicleConfirmation";
import { PersonalDetails } from "./PersonalDetails";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

interface IProps {
  handleSelectionBack: () => void;
}

export const NewTestDriveBooking: React.FC<IProps> = ({
  handleSelectionBack,
}) => {
  const website = useSelector(
    (state: IAppState) => state.consumerWebsiteReducer.consumerWebsite
  );
  const carousel = website.hasCarousel ? website.carousel : [];

  const testDriveBookingState = useSelector(
    (state: IAppState) => state.testDriveBookingReducer
  );

  const renderTestDriveBookingSteps = () => {
    const defaultWelcomeComponent = (
      <Grid container xs={12} spacing={1}>
        {website.bookingTypes.length > 1 && (
          <Grid item xs={1}>
            <Tooltip title="Go back to booking categories selection.">
              <IconButton onClick={handleSelectionBack}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        {website.hasCarousel && carousel.length && (
          <Grid container item xs={11}>
            <CarouselModule />
          </Grid>
        )}
        <Container style={{ marginTop: 50 }}>
          <AvailableTestDriveVehicles />
        </Container>
      </Grid>
    );
    switch (testDriveBookingState.currentStep) {
      case TestDriveBookingSteps.WELCOME_PAGE:
        return defaultWelcomeComponent;
      case TestDriveBookingSteps.BRANCH_SELECTION:
        return <BranchSelection />;
      case TestDriveBookingSteps.VEHICLE_SELECTION:
        return <VehicleSelection />;
      case TestDriveBookingSteps.VEHICLE_CONFIRMATION:
        return <VehicleConfirmation />;
      case TestDriveBookingSteps.PERSONAL_DETAILS:
        return <PersonalDetails />;
      case TestDriveBookingSteps.CONFIRMATION:
        return <></>;
      default:
        return defaultWelcomeComponent;
    }
  };

  return (
    <Container style={{ marginTop: 100 }}>
      {renderTestDriveBookingSteps()}
    </Container>
  );
};
