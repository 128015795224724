import { Button, Card, CardActions, CardContent, Drawer, Fab, Hidden, IconButton, List, ListItem, ListItemText, makeStyles, Modal, Paper, Tooltip, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { Storage } from "aws-amplify";
import * as _ from "lodash";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DateTime as d } from "luxon";
import dayjs from "dayjs";

import { useSelector } from "react-redux";
import {
  BookingType,
  IBookingCreateInput,
  IBookingSubscription,
  IBookingVehicleGroup,
  ILocationSurchargeInput,
  ILocationTaxSurcharge,
  IOneWayRentalFee,
  IPriceRule,
  ISubscriptionArgs,
  VehicleImages,
} from "../../../../reducers/bookings/types";
import { IVehicle } from '../../../../reducers/bookings/types';
import { IRate, IVehiclePriceGroup } from "../../../../reducers/bookings/types";
import { IAppState } from "../../../../store";
import { IFilter, IFilterOption } from "../../../FilterSection/FilterSection";
import { useSnackBar } from "../../../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../SnackbarWrapper/SnackbarWrapper";
import {
  getFeatureTypeIcon,
  getRetnalTaxAmount,
  getVehicleTypeIcon,
  toCurrency
} from "../../../utils";
import { bookingCategories, capitalizeFirstLetter } from "../utils";
import VehicleCounter from "../VehicleCounter";
import styles from './index.module.css'
import InfoIcon from '@material-ui/icons/Info';
import { IBranch } from '../../../../reducers/website/types';
import { SummaryPanel } from '../../../SummaryPanel';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SelectSubscriptionVehicleView from './SelectSubscriptionVehicleView';
import { RATE_TYPES } from '../../utils';
import Image from '../../../CarouselModule/Image'
import Slider, { Settings } from 'react-slick';
import { FiltersView } from '../../../FiltersView/FiltersView';

interface IProps {
  vehicleGroupData: any;
  locationSurcharges: ILocationSurchargeInput[];
  oneWayRentalFee: IOneWayRentalFee | undefined;
  bookingData: IBookingCreateInput;
  values: IBookingCreateInput;
  setValues: Dispatch<SetStateAction<IBookingCreateInput>>
  criteriaData: any;
  bookingType: string;
  subscriptionVehicles?: IVehicle[];
}

interface IVehicleSelection extends IVehicle {
  selected: boolean;
}

interface IRenderedVehicleGroup extends IVehiclePriceGroup {
  selectedCount: number;
  vehicles: IVehicleSelection[];
  locationSurcharges?: IPriceRule[];
}

interface InfoDisplayProps {
  items: string[];
  title: string;
  maxVisibleItems: number;
}
interface IFilterData {
  type: string;
  values: string[];
}

const initialFilters = {
  bodyType: '',
  colour: '',
  features: [],
  fuelType: '',
  make: '',
  model: '',
  numberOfDoors: '',
  numberOfSeats: '',
  transmission: '',
  year: ''
};

const settings: Settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: true,
  fade: true
};

const AntSwitch = withStyles(() =>
  createStyles({
    root: {
      width: 34,
      height: 22,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 4,
      color: "#848484",
      '&$checked': {
        transform: 'translateX(12px)',
        color: "#fff",
        '& + $track': {
          opacity: 1,
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
        },
      },
    },
    thumb: {
      width: 14,
      height: 14,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid rgba(0,0,0,0.8)`,
      borderRadius: 22 / 2,
      height: 22,
      opacity: 1,
      backgroundColor: "#fff",
    },
    checked: {},
  }),
)(Switch);

const InfoDisplay: React.FC<InfoDisplayProps> = ({
  items,
  title,
  maxVisibleItems = 2
}) => (
  <>
    <span style={{ position: "relative" }}>
      {items.slice(0, maxVisibleItems).join(", ")}
    </span>
    {items.length > maxVisibleItems && (
      <span>
        <IconButton
          style={{
            color: "black",
            padding: 0,
            paddingRight: 5,
            marginLeft: 5
          }}
        >
          <Tooltip
            title={
              <React.Fragment>
                <h3>{title}</h3>
                {items.map((item) => (
                  <p key={item}>{item}</p>
                ))}
              </React.Fragment>
            }
            arrow
            placement="right"
          >
            <InfoIcon />
          </Tooltip>
        </IconButton>
      </span>
    )}
  </>
);

const VehicleSelection: React.FC<IProps> = (props) => {

  let price = 0;
  let rentalPrice = 0;
  const snackbar = useSnackBar();
  const locationSurchargeData: ILocationTaxSurcharge[] = [];
  const branchData = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.branches);
  const organisation = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.organisation);
  const { locale, currency } = organisation;
  const { country } = organisation.address;
  const [selectedVehicleGroup, setSelectedVehicleGroup] = useState<string | undefined>();
  const [renderedVehicleGroups, setRenderedVehicleGroups] = useState<IRenderedVehicleGroup[]>([]);
  const [filteredVehicleGroups, setFilteredVehicleGroups] = useState<IVehiclePriceGroup[]>([]);
  const [vehicleGroupsImage, setVehicleGroupsImage] = useState<any[]>([]);
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [searchFilters, setSearchFilters] = useState<any>(initialFilters);
  const [smartCarOnly, setSmartCarOnly] = useState<boolean>(false);
  const [hasSmartCar, setHasSmartCar] = useState<boolean>(false);
  const [showSmartCarFilter, setShowSmartCarFilter] = useState<boolean>(true);
  const [currentBranch, setCurrentBranch] = useState<IBranch>();
  const [fleetType, setFleetType] = useState<string>("");
  const [sortOrder, setSortOrder] = useState(0)
  const [bookingDurationInWeeks, setBookingDurationInWeeks] = useState<number>(0);
  const [bookingDurationInMonths, setBookingDurationInMonths] = useState<number>(0);
  const [activeSubscriptionData, setActiveSubscriptionData] = useState<Partial<IBookingSubscription>>()

  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [vehicleImages, setVehicleImages] = useState<VehicleImages[]>([]);
  const [renderedSubscriptionVehicles, setRenderedSubscriptionVehicles] = useState<IVehicle[]>([]);
  const isTestDrive = props.bookingType === BookingType.TEST_DRIVE;
  let showVehicleGroupPrices = true;
  const bookingCategorySettings = bookingCategories[props.bookingType as BookingType];

  if (isTestDrive) {
    showVehicleGroupPrices = false;
  } else if (organisation.closeGroupSharingEnabled) {
    showVehicleGroupPrices = false;
  }

  const { values, setValues } = props;
  if (props.locationSurcharges && props.locationSurcharges.length) {
    props.locationSurcharges.forEach((surcharge: ILocationSurchargeInput) => {
      locationSurchargeData.push({
        id: surcharge.id || "",
        name: surcharge.title,
        value: surcharge.value,
        rate: surcharge.rate,
        type: surcharge.type || "FIXED",
        tax: {
          title: surcharge.taxable && surcharge.tax && surcharge.tax.title ? surcharge.tax.title : "",
          value: surcharge.taxable && surcharge.tax && surcharge.tax.value ? surcharge.tax.value : 0
        }
      });
    })
  }

  useEffect(() => {
    if (props.values && props.values.pickupDateTime) {
      setBookingDurationInMonths(d.fromISO(props.values.dropoffDateTime || "").diff(d.fromISO(props.values.pickupDateTime), ["months"]).months)
      setBookingDurationInWeeks(d.fromISO(props.values.dropoffDateTime || "").diff(d.fromISO(props.values.pickupDateTime), ["weeks"]).weeks)
    }
  }, [props.values])

  useEffect(() => {
    if (props.criteriaData) {
      setFilters(props.criteriaData)
    }
  }, [props.criteriaData]);

  useEffect(() => {
    if (props.subscriptionVehicles && props.subscriptionVehicles.length) {
      setRenderedSubscriptionVehicles(props.subscriptionVehicles)
      if (props.bookingData.activeSubscription) {
        const filterOldSubscriptionVehicle = props.subscriptionVehicles.find(vehicle => vehicle.id === props.bookingData.activeSubscription.vehicle)
        if (props.values?.activeSubscriptionExpanded && filterOldSubscriptionVehicle) {
          setActiveSubscriptionData({
            ...props.values?.activeSubscriptionExpanded,
            startDate: props.values.pickupDateTime,
            endDate: props.bookingData.dropoffDateTime
          })
        } else {
          setActiveSubscriptionData({})
          const { activeSubscription, activeSubscriptionExpanded, ...rest } = props.values
          setValues(rest)
        }
      }
    }

  }, [props.subscriptionVehicles])

  useEffect(() => {
    const selectedBranch = branchData.find((branch) => branch.id === values.branchId);
    selectedBranch && setCurrentBranch(selectedBranch)
  }, [values.branchId])

  function mergeSharedVehiclesToOwn(vehicleGroups: IVehiclePriceGroup[]) {
    const mergedGroups: IVehiclePriceGroup[] = [];
    vehicleGroups.forEach((group) => {
      const existingIndex = mergedGroups.findIndex((mergedGroup) => mergedGroup.id === group.id);
      if (existingIndex === -1) {
        mergedGroups.push({ ...group, vehicles: [...group.vehicles] });
      } else {
        mergedGroups[existingIndex].vehicles.push(...group.vehicles);
      }
    });

    return mergedGroups;
  }

  useEffect(() => {
    if (props.bookingType !== BookingType.SUBSCRIPTION) {
      let hasNonSmartCar = false;
      const vehicleGroupsArr = JSON.parse(JSON.stringify(props.vehicleGroupData));
      const updatedArr = mergeSharedVehiclesToOwn(vehicleGroupsArr)
      getVehicleGroupImage(updatedArr);
      const filteredVehicleGroupArr: IVehiclePriceGroup[] = [];
      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < updatedArr.length; index++) {
        const element = updatedArr[index];
        const availableVehicles = [];
        const ghostVehicles = [];
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < element.vehicles.length; j++) {
          const vehicle: any = element.vehicles[j];
          let available = true;
          // tslint:disable-next-line: prefer-for-of
          for (let k = 0; k < Object.keys(searchFilters).length; k++) {
            if (searchFilters[Object.keys(searchFilters)[k]].length) {

              if (!vehicle.hasOwnProperty(Object.keys(searchFilters)[k])) {
                available = false;
                break;
              }
              if (["year", "numberOfDoors", "numberOfSeats"].includes(Object.keys(searchFilters)[k])) {
                const filtersArr = searchFilters[Object.keys(searchFilters)[k]].map((item: string) => parseInt(item));
                if (!filtersArr.includes(vehicle[Object.keys(searchFilters)[k]])) {
                  available = false;
                  break;
                }
              } else if (Object.keys(searchFilters)[k] === "features") {
                let flag = true;
                if (vehicle[Object.keys(searchFilters)[k]].length) {
                  vehicle[Object.keys(searchFilters)[k]].forEach((item: string) => {
                    if (searchFilters[Object.keys(searchFilters)[k]].includes(item)) {
                      flag = false;
                    }
                  });
                }
                if (flag) {
                  available = false;
                  break;
                }
              } else {
                if (!searchFilters[Object.keys(searchFilters)[k]].includes(
                  vehicle[Object.keys(searchFilters)[k]].toLowerCase())) {
                  available = false;
                  break;
                }
              }
            }
          }
          if (vehicle.smartCarVehicleID && !hasSmartCar) {
            setHasSmartCar(true);
          }
          if (!hasNonSmartCar && !vehicle.smartCarVehicleID) {
            hasNonSmartCar = true;
          }
          if (smartCarOnly && !vehicle.smartCarVehicleID) {
            available = false
          }
          if (available) {
            const selectedBranch = branchData.find((branch) => branch.id === values.branchId);
            if (selectedBranch?.overbookingAllowed) {
              if (vehicle.isGhostVehicle) {
                ghostVehicles.push(vehicle);
              } else {
                availableVehicles.push(vehicle);
              }
            }
            else {
              if (!vehicle.isGhostVehicle) {
                availableVehicles.push(vehicle)
              }
            }
          }
        }
        element.vehicles = [...availableVehicles, ...ghostVehicles];
        if (element.vehicles.length > 0) {
          filteredVehicleGroupArr.push(element);
        }
      }
      filteredVehicleGroupArr.sort((vg1, vg2) => {
        const vg1BasePrice = vg1.basePrices.find((price: IRate) => price.rateTypeName === values.rateTypeName)
        const vg2BasePrice = vg2.basePrices.find((price: IRate) => price.rateTypeName === values.rateTypeName)
        let vg1price = 0;
        let vg2price = 0;
        if (vg1BasePrice?.applicableAmount) {
          vg1price = vg1BasePrice.applicableAmount;
        } else if (vg1BasePrice?.rate) {
          vg1price = vg1BasePrice.rate;
        }
        if (vg2BasePrice?.applicableAmount) {
          vg2price = vg2BasePrice.applicableAmount;
        } else if (vg2BasePrice?.rate) {
          vg2price = vg2BasePrice.rate;
        }
        return sortOrder ? vg2price - vg1price : vg1price - vg2price;
      });
      setFilteredVehicleGroups(filteredVehicleGroupArr);
      if (!hasNonSmartCar) {
        setShowSmartCarFilter(false);
        setSmartCarOnly(true);
      }
    } else {
      const availableVehicles = [];
      // tslint:disable-next-line: prefer-for-of
      const vehicles = JSON.parse(JSON.stringify(props.subscriptionVehicles))
      for (let i = 0; i < vehicles.length; i++) {
        const vehicle: any = vehicles[i];
        let available = true;
        // tslint:disable-next-line: prefer-for-of
        for (let k = 0; k < Object.keys(searchFilters).length; k++) {
          if (searchFilters[Object.keys(searchFilters)[k]].length) {
            setValues({
              ...values,
              vehicleGroups: []
            })
            if (!vehicle.hasOwnProperty(Object.keys(searchFilters)[k])) {
              available = false;
              break;
            }
            if (["year", "numberOfDoors", "numberOfSeats"].includes(Object.keys(searchFilters)[k])) {
              const filtersArr = searchFilters[Object.keys(searchFilters)[k]].map((item: string) => parseInt(item));
              if (!filtersArr.includes(vehicle[Object.keys(searchFilters)[k]])) {
                available = false;
                break;
              }
            } else if (Object.keys(searchFilters)[k] === "features") {
              let flag = true;
              if (vehicle[Object.keys(searchFilters)[k]].length) {
                vehicle[Object.keys(searchFilters)[k]].forEach((item: string) => {
                  if (searchFilters[Object.keys(searchFilters)[k]].includes(item)) {
                    flag = false;
                  }
                });
              }
              if (flag) {
                available = false;
                break;
              }
            } else {
              if (!searchFilters[Object.keys(searchFilters)[k]].includes(
                vehicle[Object.keys(searchFilters)[k]].toLowerCase())) {
                available = false;
                break;
              }
            }
          }
        }
        if (available) {
          availableVehicles.push(vehicle)
        }
      }
      setRenderedSubscriptionVehicles(availableVehicles)
    }
  }, [searchFilters, smartCarOnly, sortOrder, props.subscriptionVehicles]);

  useEffect(() => {
    if (hasSmartCar) {
      setValues({
        ...values,
        vehicleGroups: [],
        smartCarVehicle: smartCarOnly
      })
    }
  }, [smartCarOnly])

  useEffect(() => {
    let bookingVehicleGroups = [...props.values.vehicleGroups];
    let tempGroupArray: IRenderedVehicleGroup[] = []
    tempGroupArray = filteredVehicleGroups.length ? filteredVehicleGroups.map((vg) => {
      let selectedCount: number = 0;
      let tempVehicles: IVehicleSelection[] = vg.vehicles.map((vehicle) => {
        return {
          ...vehicle,
          selected: false
        }
      });
      if (bookingVehicleGroups.length) {
        bookingVehicleGroups.map((bookingVg) => {
          if (bookingVg.vehicleGroup === vg.id) {
            bookingVg.vehicleIds.map((vehicleId) => {
              const vehicleIndex = tempVehicles.findIndex(item => item.id === vehicleId)
              if (vehicleIndex >= 0) {
                selectedCount += 1;
                tempVehicles[vehicleIndex].selected = true;
              }
            })
          }
          else {
            bookingVg.vehicleIds.map((vehicleId) => {
              const vehicleIndex = tempVehicles.findIndex(item => item.id === vehicleId)
              if (vehicleIndex >= 0) {
                tempVehicles.splice(vehicleIndex, 1);
              }
            })
          }
        })
      }
      return {
        ...vg,
        selectedCount,
        vehicles: tempVehicles
      }
    }) : []
    setRenderedVehicleGroups(tempGroupArray);
  }, [filteredVehicleGroups])

  const getDoorString = (item: IVehiclePriceGroup) => {
    const doorSet = new Set();
    item.vehicles.forEach((vehicle: IVehicle) => {
      doorSet.add(vehicle.numberOfDoors);
    });
    let outputArr = Array.from(doorSet);
    outputArr = outputArr.filter((item: any) => item > 0).sort();
    return (
      <span className="flex cross-center">
        <img
          style={{ height: "12px", width: "12px", marginRight: "2px" }}
          src={getFeatureTypeIcon("numberOfDoors")}
          alt={"numberOfDoors"}
        />
        <span style={{ position: "relative" }}>
          {outputArr.join(",")}
        </span>
      </span>
    );
  };

  const getSeatString = (item: IVehiclePriceGroup) => {
    const seatSet = new Set();
    item.vehicles.forEach((vehicle: IVehicle) => {
      seatSet.add(vehicle.numberOfSeats);
    });
    let outputArr = Array.from(seatSet);
    outputArr = outputArr.filter((item: any) => item > 0).sort();
    return (
      <span className="flex cross-center">
        <img
          style={{ height: "12px", width: "12px", marginRight: "5px" }}
          src={getFeatureTypeIcon("numberOfSeats")}
          alt={"numberOfSeats"}
        />
        <span style={{ position: "relative" }}>
          {outputArr.join(",")}
        </span>
      </span>
    );
  };

  const getTransmissionString = (item: IVehiclePriceGroup) => {
    const transmmissionSet = new Set();
    item.vehicles.forEach((vehicle: IVehicle) => {
      transmmissionSet.add(vehicle.transmission.charAt(0).toUpperCase() + vehicle.transmission.slice(1).toLowerCase());
    });
    let outputArr: any[] = Array.from(transmmissionSet);
    outputArr = outputArr.map(item => item.slice(0, 1))
    return (
      <span className="flex cross-center">
        <img
          style={{ height: "12px", width: "12px", marginRight: "5px" }}
          src={getFeatureTypeIcon("transmission")}
          alt={"transmission"}
        />
        <span style={{ position: "relative" }}>
          {outputArr.join(",")}
        </span>
      </span>
    );
  };

  const getFuelTypeString = (item: IVehiclePriceGroup) => {
    const fuelSet: Set<string> = new Set();
    item.vehicles.forEach((vehicle: IVehicle) => {
      fuelSet.add(vehicle.fuelType.charAt(0).toUpperCase() + vehicle.fuelType.slice(1).toLowerCase());
    });
    const outputArr: string[] = Array.from(fuelSet);
    return (
      <span className="flex cross-center">
        <img
          style={{ height: "12px", width: "12px", marginRight: "5px" }}
          src={getFeatureTypeIcon("fuelType")}
          alt={"fuelType"}
        />
        <InfoDisplay items={outputArr} maxVisibleItems={2} title="Fuel Type" />
      </span>
    );
  };

  const renderRatyeTypeName = (rateType: string) => {
    if (rateType === "weekly") {
      return "Per Week"
    } else if (rateType === "daily") {
      return "Per Day"
    } else if (rateType === "hourly") {
      return "Per Hour"
    } else {
      return "Per Month"
    }
  }

  const renderGroupBasePrice = (item: IRenderedVehicleGroup) => {
    const basePrice = item.basePrices.find((price: IRate) => price.rateTypeName === values.rateTypeName);
    let onDiscount = true;
    let totalSurcharge = 0;
    if (basePrice) {
      if (basePrice.applicableAmount) {
        price = basePrice.applicableAmount;
        rentalPrice = basePrice.applicableAmount;
      } else {
        price = basePrice.rate;
        rentalPrice = basePrice.rate;
      }
      if (price > basePrice.rate) {
        onDiscount = false;
      }
      if (props.locationSurcharges && props.locationSurcharges.length) {
        props.locationSurcharges.forEach((surcharge: ILocationSurchargeInput) => {
          if (surcharge.type === "PERCENTAGE") {
            totalSurcharge = totalSurcharge + (rentalPrice * surcharge.value / 100);
          } else {
            totalSurcharge = totalSurcharge + surcharge.value;
          }
        })
      }
      if (totalSurcharge) {
        price = price + totalSurcharge;
      }
      return (
        <>
          {(!basePrice.applicableAmount && !totalSurcharge) ?
            (
              <>
                <Hidden smDown>
                  <span className="flex fill"></span>
                </Hidden>
                <div>
                  <span className={styles.currentPrice}>
                    {toCurrency(basePrice.rate, currency, locale)}
                  </span>
                  {!organisation.closeGroupSharingEnabled ? <span className={styles.priceName}>{renderRatyeTypeName(basePrice.rateTypeName)}</span> : ""}
                </div>
              </>
            )
            :
            (
              <>
                <Hidden smDown>
                  <span className="flex fill"></span>
                </Hidden>
                <div>
                  <span className={styles.currentPrice}>
                    {toCurrency(price, currency, locale)}
                  </span>
                  {!organisation.closeGroupSharingEnabled ? <span className={styles.priceName}>{renderRatyeTypeName(basePrice.rateTypeName)}</span> : ""}
                </div>
              </>
            )}
        </>
      )
    }
  };

  const getVehicleGroupImage = (vehicleGroupsList: IVehiclePriceGroup[]) => {
    vehicleGroupsList.map(async (vehicleGroup: any) => {
      try {
        if (vehicleGroup && vehicleGroup.images) {
          const config = { contentType: "image/*", level: "public" };
          if (vehicleGroup?.images?.length) {
            const imageUrls = await Promise.all(vehicleGroup.images.map(async (image: any) => {
              const url = image?.url ? await Storage.get(image.url, config).then((result) => {
                return result;
              }) : "";
              return {
                default: image.default,
                idx: image.idx,
                url: url,
              };
            }))
            const sortedImages = imageUrls.sort((a: any, b: any) => b.default - a.default)
            if (imageUrls.length > 0) {
              setVehicleGroupsImage([...vehicleGroupsImage, {
                vehicleGroupId: vehicleGroup.id,
                images: sortedImages
              }]);
            }
          }
        }
      } catch (error) {
        snackbar({
          message: "Invalid File Type. Allowed type: jpeg or png",
          variant: SnackBarVariant.ERROR
        });
      }
    });
  };

  const getMileageLimitText = (mileageLimit: number, rateType: string) => {
    switch (rateType) {
      case "hourly":
        return `${mileageLimit} ${currentBranch?.distanceUnit}s per hour`;
      case "daily":
        return `${mileageLimit} ${currentBranch?.distanceUnit}s per day`;
      case "weekly":
        return `${mileageLimit} ${currentBranch?.distanceUnit}s per week`;
      case "monthly":
        return `${mileageLimit} ${currentBranch?.distanceUnit}s per month`;
    }
  }

  const taxes = currentBranch && currentBranch.addTaxOptIn ? currentBranch.taxes.map((tax) => {
    return {
      code: tax.title,
      type: tax.type,
      rate: tax.rate,
      amount: tax.value,
      subdivisionOptIn: tax.subdivisionOptIn,
      subdivisionRate: tax.subdivisionRate,
      subdivisionType: tax.subdivisionType,
      subdivisionValue: tax.subdivisionValue
    }
  }) : [];

  const taxHasValue = () => {
    const taxAmount = getRetnalTaxAmount(taxes, 100, values.rateTypeDuration, 1);
    return Boolean(taxAmount);
  }

  const handleImageClick = (index: number, vehicleImages: VehicleImages[]): void => {
    setSelectedImageIndex(index);
    setIsModalOpen(true);
    setVehicleImages(vehicleImages)
  };

  const handleClose = (): void => {
    setIsModalOpen(false);
    setSelectedImageIndex(null);
    setVehicleImages([]);
  };

  const renderVehicleGroups = () => {
    if (renderedVehicleGroups.length) {
      let hasVehicleWithNonZeroPrice = false;
      const renderArray = renderedVehicleGroups.map((item: IRenderedVehicleGroup, idx: number) => {
        if (item.vehicles.length) {
          const basePrice = item.basePrices.find((price: IRate) => price.rateTypeName === values.rateTypeName);
          let isPriceZero = false;
          if (basePrice) {
            if (basePrice.hasOwnProperty("applicableAmount") && typeof basePrice.applicableAmount === "number") {
              if (basePrice.applicableAmount === 0) {
                isPriceZero = true;
              }
            } else {
              if (basePrice.rate === 0) {
                isPriceZero = true;
              }
            }
          }
          vehicleGroupsImage.forEach((vgImg: any) => {
            if (vgImg.vehicleGroupId === item.id) {
              item.images = vgImg.images;
            }
          });
          if (item.vehicles.length && !isPriceZero) {
            if (!hasVehicleWithNonZeroPrice) {
              hasVehicleWithNonZeroPrice = true
            }
            return (
              <>
                <Hidden smDown>
                  <div className={styles.vehicleGroupcard} >
                    <div className={styles.leftBorder} style={selectedVehicleGroup === item.id ? { transform: 'translateX(4px)' } : {}} />
                    <Grid container>
                      <Grid item md={2} className={styles.groupIconWrap}>
                        {
                          item?.images?.length > 1 ? (
                            <Slider className={`${styles.slickySlider} slider-mini-vg`} {...settings} >
                              {
                                item?.images.map((image, index) => {
                                  return (
                                    <div key={index} onClick={() => handleImageClick(index, item?.images)}>
                                      <Image imageKey={image.url} />
                                    </div>
                                  )
                                })
                              }
                            </Slider>
                          ) : item?.images?.length === 1 ? (
                            <div onClick={() => handleImageClick(0, item?.images)}>
                              <Image imageKey={item.images[0].url} />
                            </div>
                          ) : (
                            <img
                              src={require("../../../../assets/icons/vehicle-group.svg").default}
                              alt="group1 icon"
                              style={{ width: "100%", height: 'auto' }}
                            />
                          )
                        }
                      </Grid>
                      <Grid item md={10} className={styles.groupInfoWrap}>
                        <div className="flex cross-start">
                          <div className="flex font-Regular bold" style={{ width: 200 }}>
                            {item.name}
                          </div>
                          <div className={styles.vehicleFeatureWrap}>
                            {bookingCategorySettings.showMileage &&
                              <div className={styles.vehicleFeature}>
                                {basePrice && basePrice.mileageLimit > 0 && (
                                  <span>
                                    <img
                                      src={require("../../../../assets/icons/speedometer.png").default}
                                      alt="speedometer"
                                      style={{ height: "18px", width: "18px", marginRight: "10px" }}
                                    />
                                    <span style={{ position: "relative", bottom: "4px" }}>
                                      {getMileageLimitText(basePrice.mileageLimit, basePrice.rateTypeName)}
                                    </span>
                                  </span>
                                )}
                                {basePrice && basePrice.unlimitedMileage && (
                                  <span>
                                    <img
                                      src={require("../../../../assets/icons/speedometer.png").default}
                                      alt="speedometer"
                                      style={{ height: "18px", width: "18px", marginRight: "10px" }}
                                    />
                                    <span style={{ position: "relative", bottom: "4px" }}>
                                      {"Unlimited Mileage"}
                                    </span>
                                  </span>
                                )}
                              </div>
                            }
                            <div className={styles.vehicleFeature}>
                              {getDoorString(item)}
                            </div>
                            <div className={styles.vehicleFeature}>
                              {getSeatString(item)}
                            </div>
                            <div className={styles.vehicleFeature}>
                              {getTransmissionString(item)}
                            </div>
                            <div className={styles.vehicleFeature}>
                              {getFuelTypeString(item)}
                            </div>
                          </div>
                          {showVehicleGroupPrices && (
                            <div className={styles.vehiclePrice}>
                              {renderGroupBasePrice(item)}
                              <span style={{ marginLeft: 5, fontSize: 12, opacity: 0.6, fontWeight: 800 }}>{taxHasValue() && `+ ${country === "United States" ? "Taxes & Fees" : "VAT"}`}</span>
                              <span>
                                <IconButton style={{
                                  color: "black",
                                  padding: 0,
                                  paddingRight: 5,
                                  marginLeft: 10
                                }}>
                                  <Tooltip
                                    title={
                                      <React.Fragment>
                                        <p>{`Rental (1 x ${toCurrency(rentalPrice, currency, locale)}) = ${toCurrency(rentalPrice, currency, locale)}`}</p>
                                        {
                                          props.locationSurcharges && props.locationSurcharges.length ?
                                            props.locationSurcharges.map((surcharge: ILocationSurchargeInput) => (
                                              <div>
                                                <p>{surcharge.title} = {surcharge.type === "PERCENTAGE" ? toCurrency(rentalPrice * surcharge.value / 100, currency, locale) : toCurrency(surcharge.value, currency, locale)}</p>
                                              </div>
                                            )) : ""
                                        }
                                      </React.Fragment>
                                    }
                                    arrow
                                    placement={"right"}
                                  >
                                    <InfoIcon />
                                  </Tooltip>
                                </IconButton>
                              </span>
                            </div>
                          )}

                        </div>
                        <div className="flex cross-center">
                          {bookingCategorySettings.showVehicleAvailableCount &&
                            <span className="font-medium" style={{ marginRight: 25 }}>{`Available: ${item.vehicles.length}`}</span>
                          }
                          <div className="flex fill main-end cross-center">
                            <VehicleCounter
                              count={item.selectedCount || 0}
                              maxCount={smartCarOnly ? 1 : item.vehicles.length}
                              vehicleGroup={item}
                              updateCounter={updateVehicleSelection}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Hidden>
                <Hidden mdUp>
                  <Grid item container xs={12} sm={6} >
                    <div className={`${styles.vehicleGroupcard} ${styles.mobile}`}>
                      <Grid container item xs={12}>
                        <Grid item xs={6}>
                          {!organisation.closeGroupSharingEnabled && (
                            <>
                              <div className={`${styles.vehiclePrice} ${styles.mobile}`}>
                                <Typography>
                                  {renderGroupBasePrice(item)}
                                  <span style={{ marginLeft: 5, fontSize: 12, opacity: 0.6, fontWeight: 800 }}>{taxHasValue() && `+ ${country === "United States" ? "Taxes & Fees" : "VAT"}`}</span>
                                </Typography>
                              </div>
                            </>
                          )}
                          <div className="font-Regular bold padding-right">
                            {item.name}
                          </div>
                          <div className="margin-top padding-top">
                            <div className={`${styles.vehicleFeature} ${styles.mobile}`}>
                              {getTransmissionString(item)}
                            </div>
                            <div className={`${styles.vehicleFeature} ${styles.mobile}`}>
                              {getDoorString(item)}
                            </div>
                            <div className={`${styles.vehicleFeature} ${styles.mobile}`}>
                              {getSeatString(item)}
                            </div>
                          </div>
                          <div className=" flex col-flex margin-bottom padding-bottom">
                            <div className={`${styles.vehicleFeature} ${styles.mobile}`}>
                              {getFuelTypeString(item)}
                            </div>
                            <div className={`${styles.vehicleFeature} ${styles.mobile}`}>
                                {basePrice && (
                                  <span>
                                    <img
                                      src={require("../../../../assets/icons/speedometer.png").default}
                                      alt="speedometer"
                                      style={{ height: "12px", width: "12px", marginRight: "5px" }}
                                    />
                                    <span>
                                      {basePrice.mileageLimit > 0 && getMileageLimitText(basePrice.mileageLimit, basePrice.rateTypeName)}
                                      {basePrice.unlimitedMileage && "Unlimited Mileage"}
                                    </span>
                                  </span>
                                )}
                              </div>
                          </div>
                        </Grid>
                        <Grid item xs={6} className={`${styles.groupIconWrap} ${styles.mobile}`}>
                          {
                            item?.images?.length > 1 ? (
                              <Slider className={`${styles.slickySlider} slider-mini-vg`} {...settings} >
                                {
                                  item?.images.map((image, index) => {
                                    return (
                                      <Image key={index} imageKey={image.url} />
                                    )
                                  })
                                }
                              </Slider>
                            ) : item?.images?.length === 1 ? (
                              <Image imageKey={item.images[0].url} />
                            ) : (
                              <img
                                src={require("../../../../assets/icons/vehicle-group.svg").default}
                                alt="group1 icon"
                                style={{ width: "100%", height: 'auto' }}
                              />
                            )
                          }
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                      </Grid>
                      <Grid container item xs={12} alignItems="center">
                        {bookingCategorySettings.showVehicleAvailableCount &&
                          <Grid item xs={6}>
                            <Typography variant="body1" className="text-grey">{`Available: ${item.vehicles.length}`}</Typography>
                          </Grid>
                        }
                        <Grid item xs={6}>
                          <div className="flex fill cross-center">
                            <VehicleCounter
                              count={item.selectedCount || 0}
                              maxCount={smartCarOnly ? 1 : item.vehicles.length}
                              vehicleGroup={item}
                              updateCounter={updateVehicleSelection}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Hidden>
              </>
            );
          }
        }
      });
      if (hasVehicleWithNonZeroPrice) {
        return renderArray
      }
      return <div className={styles.vehicleGroupcard} style={{ padding: 15, border: 0 }}>No groups found with matching criteria!</div>
    } else if (!filteredVehicleGroups.length) {
      return <div className={styles.vehicleGroupcard} style={{ padding: 15, border: 0 }}>No groups found with matching criteria!</div>;
    }
  };

  const updateVehicleSelection = (focusedGroup: IVehiclePriceGroup, isIncrease: boolean, vehicleId?: string) => {
    const vehicleGroupIndex = renderedVehicleGroups.findIndex((vg) => vg.id === focusedGroup.id);
    const vehicleGroup: IRenderedVehicleGroup = renderedVehicleGroups[vehicleGroupIndex];
    let tempGroupArray = [...renderedVehicleGroups];
    if ((smartCarOnly && isIncrease) || bookingCategorySettings.maxVehiclesAllowed === 1) {
      for (let i = 0; i < tempGroupArray.length; i++) {
        tempGroupArray[i].selectedCount = 0;
        for (const vehicle of tempGroupArray[i].vehicles) {
          vehicle.selected = false;
        }
      }
    }
    const tempVehicles = tempGroupArray[vehicleGroupIndex].vehicles;
    const count = vehicleGroup.selectedCount || 0;
    let bookingVehicleGroups: IBookingVehicleGroup[] =
      (smartCarOnly && isIncrease) ||
        (isIncrease &&
          bookingCategorySettings.maxVehiclesAllowed === 1)
        ? []
        : [...values.vehicleGroups];
    let checkCount = smartCarOnly ? 1 : vehicleGroup.vehicles.length;
    if (isIncrease) {
      if ((!vehicleId && count < checkCount) || vehicleId) {
        let selectedVehicleId: string = '';
        for (var i = 0; i < tempVehicles.length; i++) {
          if ((!vehicleId && !tempVehicles[i].selected) || (vehicleId && tempVehicles[i].id === vehicleId)) {
            tempGroupArray[vehicleGroupIndex].vehicles[i].selected = true;
            selectedVehicleId = tempVehicles[i].id as string;
            let bookingVG = bookingVehicleGroups.find(vg => vg.vehicleGroup === tempGroupArray[vehicleGroupIndex].id)
            if (bookingVG) {
              bookingVG.vehicleIds.push(selectedVehicleId);
              bookingVG.count += 1;
            }
            const basePrice = tempGroupArray[vehicleGroupIndex].basePrices.find(
              (item) => item.rateTypeName === values.rateTypeName
            );
            if (!bookingVG && basePrice) {
              bookingVehicleGroups.push({
                name: tempGroupArray[vehicleGroupIndex].name,
                baseRate: basePrice.rate || 0,
                count: 1,
                deposit: tempGroupArray[vehicleGroupIndex].deposit,
                mileageLimit: basePrice.mileageLimit,
                pricePerExtraMile: basePrice.pricePerExtraMile,
                unlimitedMileage: basePrice.unlimitedMileage ? basePrice.unlimitedMileage : false,
                ...(basePrice.priceRule && { priceRule: basePrice.priceRule }),
                ...(basePrice.priceRuleAmount && { priceRuleAmount: basePrice.priceRuleAmount }),
                ...(basePrice.priceRuleName && { priceRuleName: basePrice.priceRuleName }),
                ...(basePrice.priceRuleOperator && { priceRuleOperator: basePrice.priceRuleOperator }),
                ...(basePrice.priceRuleValueType && { priceRuleValueType: basePrice.priceRuleValueType }),
                ...(basePrice.priceRuleTriggerType && { priceRuleTriggerType: basePrice.priceRuleTriggerType }),
                vehicleGroup: tempGroupArray[vehicleGroupIndex].id as string,
                vehicleIds: [selectedVehicleId],
              });
            }
            break;
          }
        }
        tempGroupArray.map((vg, index) => {
          if (index !== vehicleGroupIndex) {
            vg.vehicles = vg.vehicles.filter(vehicle => vehicle?.id !== selectedVehicleId);
          }
        });
        tempGroupArray[vehicleGroupIndex].selectedCount = count + 1;
      }
    }
    else {
      if (count > 0) {
        let unselectedVehicleId: string = '';
        for (let i = tempVehicles.length - 1; i >= 0; i--) {
          if ((!vehicleId && tempVehicles[i].selected) || (vehicleId && tempVehicles[i].id === vehicleId)) {
            tempGroupArray[vehicleGroupIndex].vehicles[i].selected = false;
            unselectedVehicleId = tempVehicles[i].id as string;
            let bookingVGIndex = bookingVehicleGroups.findIndex(vg => vg.vehicleGroup === tempGroupArray[vehicleGroupIndex].id);
            bookingVehicleGroups[bookingVGIndex].vehicleIds = bookingVehicleGroups[bookingVGIndex].vehicleIds.filter(id => id !== unselectedVehicleId);
            bookingVehicleGroups[bookingVGIndex].count -= 1
            if (!bookingVehicleGroups[bookingVGIndex].vehicleIds.length) {
              bookingVehicleGroups.splice(bookingVGIndex, 1);
            }
            break;
          }
        }
        filteredVehicleGroups.map((vg, groupIndex) => {
          let tempVehicleArray: IVehicleSelection[] = [];
          if (groupIndex !== vehicleGroupIndex) {
            vg.vehicles.map((vehicle) => {
              const vehicleIndex = tempGroupArray[groupIndex].vehicles.findIndex(item => item.id === vehicle.id)
              if (vehicleIndex >= 0) {
                tempVehicleArray.push(tempGroupArray[groupIndex].vehicles[vehicleIndex]);
              }
              if ((vehicle.id === unselectedVehicleId)) {
                tempVehicleArray.push({ ...vehicle, selected: false })
              }
            });
            tempGroupArray[groupIndex].vehicles = tempVehicleArray
          }
        });
        tempGroupArray[vehicleGroupIndex].selectedCount = count - 1;
      }
    }
    setRenderedVehicleGroups(tempGroupArray);

    let isCarShare = false;
    if (smartCarOnly) {
      const selectedVehicleGroupId = bookingVehicleGroups[0].vehicleGroup
      const selectedVehicleId = bookingVehicleGroups[0].vehicleIds[0]

      const selectedVehicle = renderedVehicleGroups?.find(
        vg => vg.id === selectedVehicleGroupId
      )?.vehicles.find(
        vehicle => vehicle.id === selectedVehicleId
      )

      if (selectedVehicle?.isCarShare && organisation.allowCarSharing) {
        isCarShare = true
      }
    }
    const { isSubscription, activeSubscription, activeSubscriptionExpanded, ...rest } = values
    setValues({
      ...rest,
      locationSurcharges: locationSurchargeData,
      vehicleGroups: bookingVehicleGroups,
      isCarShare: isIncrease ? isCarShare : false
    });
    setFleetType(vehicleGroup.fleetType || "");
  }

  const showSummary = () => {
    let selectedVehiclesCount = 0;
    if (values.isSubscription) {
      selectedVehiclesCount = 1;
    } else {
      values.vehicleGroups.forEach((group) => {
        selectedVehiclesCount += group.vehicleIds.length;
      })
    }
    return !isTestDrive && selectedVehiclesCount > 0;
  }

  const handleFilterUpdate = (filters: { [key: string]: string[] }) => {
    setSearchFilters({
      ...searchFilters,
      ...filters
    });
  };

  const onSelectSubscription = (selectedSubscription: ISubscriptionArgs, vehicle: IVehicle) => {
    // const booking = { ...values }
    // if (booking.insuranceName || booking.addonRequirements) {
    //   booking.insuranceName = ""
    //   booking.insurancePolicy = ""
    //   booking.insuranceRate = 0
    //   booking.addonRequirements = []
    // }
    // if (selectedSubscription && selectedSubscription.subscription) {
    //   const weeklySubscription = selectedSubscription.subscription.rateTypeName === RATE_TYPES.WEEKLY ? true : false
    //   const activeSubscription = {
    //     duration: selectedSubscription.pricing.duration,
    //     endDate: props.bookingData.dropoffDateTime || "",
    //     enrollmentAmount: selectedSubscription.pricing.enrollmentAmount,
    //     subscriptionAmount: selectedSubscription.pricing.subscriptionAmount,
    //     startDate: props.bookingData.pickupDateTime,
    //     subscription: selectedSubscription.subscription.id,
    //     vehicle: vehicle?.id || "",
    //     unlimitedMileage: selectedSubscription.pricing.unlimitedMileage,
    //     mileageLimit: selectedSubscription.pricing.mileage,
    //     pricePerExtraMile: selectedSubscription.pricing.pricePerExtraMile,
    //     swapsAllowed: selectedSubscription.pricing.swapsAllowed,
    //     deposit: selectedSubscription.deposit
    //   }
    //   setActiveSubscriptionData({
    //     ...activeSubscription,
    //     subscription: selectedSubscription.subscription,
    //     vehicle: vehicle
    //   })
    //   setValues({
    //     ...booking,
    //     vehicleGroups: [],
    //     isSubscription: true,
    //     activeSubscription,
    //     billingCycleDuration: weeklySubscription ? 10080 : 43200,
    //     billingCycleName: weeklySubscription ? RATE_TYPES.WEEKLY : RATE_TYPES.MONTHLY,
    //     rateTypeDuration: weeklySubscription ? 10080 : 43200,
    //     rateTypeName: weeklySubscription ? RATE_TYPES.WEEKLY : RATE_TYPES.MONTHLY,
    //     activeSubscriptionExpanded: {
    //       ...activeSubscription,
    //       subscription: selectedSubscription.subscription,
    //       vehicle: vehicle
    //     },
    //     locationSurcharges: [],
    //     defaultBillingCycle: false
    //   })
    // }
  }

  const removeSubscription = () => {
    setValues({
      ...values,
      isSubscription: false,
      vehicleGroups: [],
      insuranceName: "",
      insurancePolicy: "",
      insuranceRate: 0,
      addonRequirements: [],
      defaultBillingCycle: true
    })
  }

  const clearFilters = () => {
    setSearchFilters(initialFilters)
  }

  const showGridForSubscription: boolean = props.bookingType === BookingType.SUBSCRIPTION && !showSummary()

  return (
    <div className={styles.searchResult}>
      {
        <Grid container spacing={2}>
          {!showSummary() &&
            <Hidden smDown>
              {
                props.bookingType !== BookingType.SUBSCRIPTION && (
                  <Grid item xs={12} md={2} style={{ marginTop: "4rem" }}>
                    <FiltersView
                      filters={filters}
                      onChange={handleFilterUpdate}
                    />
                  </Grid>
                )
              }
            </Hidden>
          }
          <Grid item xs={12} md={showGridForSubscription ? 12 : 8} lg={showGridForSubscription ? 12 : 9} container style={{ display: "block" }}>
            <Grid item xs={12} container spacing={2}>
              <Grid container item xs={12} alignItems='center'>
                <Typography variant="h4" style={{ fontWeight: 700 }} >Search Results</Typography>
                {
                  !organisation.closeGroupSharingEnabled && bookingCategorySettings.showFilters &&
                  <div className="flex fill main-end cross-center">
                    {
                      bookingCategorySettings.showPriceSort && (
                        <div className={styles.sortAction}>
                          <div className={styles.sortButton}>
                            <ImportExportIcon fontSize="small" />
                            <Typography variant="h4" style={{ fontWeight: 700 }} >Sort</Typography>
                          </div>
                          <div className={styles.sortOptions}>
                            <p onClick={() => setSortOrder(0)}>Price low to high</p>
                            <p onClick={() => setSortOrder(1)}> Price high to low</p>
                          </div>
                        </div>
                      )
                    }
                  </div>
                }
              </Grid>
              {
                bookingCategorySettings.showKeylessVehiclesToggle && hasSmartCar && showSmartCarFilter && <Grid item xs={12} md={12} style={{ marginBottom: 20 }}>
                  <div style={{ background: "#fff", borderRadius: 6, padding: "10px 20px" }}>
                    <Typography variant="subtitle1" style={{ fontWeight: 700 }}>We have smart cars</Typography>
                    <Typography variant="body1" style={{ borderBottom: "1px solid #e2e2e2", paddingBottom: 10, color: "#848484" }}>We provide keyless entry support with our smart cars</Typography>
                    <div className="flex space-between padding-top">
                      <Typography variant="body1">Show Keyless Vehicles</Typography>
                      <AntSwitch checked={smartCarOnly} onChange={() => setSmartCarOnly(prev => !prev)} />
                    </div>
                  </div>
                </Grid>
              }
              <Grid container item sm={12}>
                {
                  values.isSubscription && activeSubscriptionData && (
                    <Grid item xs={12}>
                      <Card component={Paper} elevation={0} className={styles.subscriptionCarMain}>
                        <CardContent>
                          <Grid item xs={12} container spacing={2} alignItems='center'>
                            <Grid item xs={3}>
                              {activeSubscriptionData.vehicle?.imageUrl ? (
                                <img
                                  className="fleet-resrc-img"
                                  alt="vehicle type icon"
                                  src={activeSubscriptionData.vehicle.imageUrl}
                                  height="90"
                                  width="180"
                                />
                              ) : (
                                <img
                                  className="fleet-resrc-img"
                                  alt="vehicle type icon"
                                  src={getVehicleTypeIcon(
                                    capitalizeFirstLetter(activeSubscriptionData.vehicle?.bodyType || "")
                                  )}
                                />
                              )}
                            </Grid>
                            <Grid item xs={9} container>
                              <Grid item xs={12}><Typography gutterBottom style={{ fontSize: 16, textTransform: "uppercase", fontWeight: 600, marginBottom: 10 }} variant="h3">{activeSubscriptionData.subscription?.name}</Typography></Grid>
                              <Grid item xs={4}>
                                <Typography gutterBottom variant="h4">Start Date: <span style={{ fontSize: 14, fontWeight: 300 }}>{dayjs(activeSubscriptionData.startDate).tz().format("DD/MM/YYYY")}</span> </Typography>
                                <Typography gutterBottom variant="h4">End Date : <span style={{ fontSize: 14, fontWeight: 300 }}>{dayjs(activeSubscriptionData.endDate).tz().format("DD/MM/YYYY")}</span> </Typography>
                                <Typography gutterBottom variant="h4">Duration : <span style={{ fontSize: 14, fontWeight: 300 }}>{`0${activeSubscriptionData.duration} ${activeSubscriptionData.subscription?.rateTypeName === RATE_TYPES.WEEKLY ? "Weeks" : "Months"}(Min)`}</span></Typography>
                                <Typography gutterBottom variant="h4">Swaps Allowed : <span style={{ fontSize: 14, fontWeight: 300 }}>{activeSubscriptionData.swapsAllowed || "N/A"}</span> </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography gutterBottom variant="h4">Enrollment Amount: <span style={{ fontSize: 14, fontWeight: 300 }}>{toCurrency(activeSubscriptionData.enrollmentAmount || 0, currency, locale)}</span> </Typography>
                                <Typography gutterBottom variant="h4">Subscription Amount: <span style={{ fontSize: 14, fontWeight: 300 }}>{toCurrency(activeSubscriptionData.subscriptionAmount || 0, currency, locale)}</span> </Typography>
                                <Typography gutterBottom variant="h4">Mileage Limit: <span style={{ fontSize: 14, fontWeight: 300 }}>{activeSubscriptionData.unlimitedMileage ? "Unlimited" : activeSubscriptionData.mileageLimit}</span> </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                {
                                  activeSubscriptionData.unlimitedMileage ?
                                    <Typography gutterBottom variant="h4">Price per extra mile: <span style={{ fontSize: 14, fontWeight: 300 }}>N/A</span></Typography>
                                    :
                                    <Typography gutterBottom variant="h4">Price per extra mile: <span style={{ fontSize: 14, fontWeight: 300 }}>{toCurrency(activeSubscriptionData.pricePerExtraMile || 0, currency, locale)}</span></Typography>
                                }
                                <Typography gutterBottom variant="h4">Insurance Policy: <span style={{ fontSize: 14, fontWeight: 300 }}>{activeSubscriptionData.subscription?.insurancePolicy?.name || "-"}</span>
                                </Typography>
                                <Typography gutterBottom variant="h4">Addons: <span style={{ fontSize: 14, fontWeight: 300 }}>{activeSubscriptionData.subscription?.addons?.map(addon => addon.displayName).join(", ") || "-"}</span>
                                </Typography>

                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardActions>
                          <Grid item xs={12} container justify="flex-end">
                            <Button
                              onClick={() => removeSubscription()}
                            >Remove Subscription</Button>
                          </Grid>
                        </CardActions>
                      </Card>
                    </Grid>
                  )
                }
              </Grid>
              <Grid item xs={12} container className={values.isSubscription ? styles.disableRentals : ""}>
                {
                  (props.bookingType === BookingType.SUBSCRIPTION) ?
                    renderedSubscriptionVehicles?.length ? (
                      <SelectSubscriptionVehicleView
                        subscriptionVehicles={renderedSubscriptionVehicles}
                        bookingDurationInMonths={bookingDurationInMonths}
                        showGridForSubscription={showGridForSubscription}
                        bookingDurationInWeeks={bookingDurationInWeeks}
                        onSelectSubscription={onSelectSubscription}
                      />
                    ) : (
                      <div className={styles.vehicleGroupcard} style={{ padding: 15, border: 0 }}>No groups found with matching criteria!</div>
                    ) : ""
                }

                {
                  [BookingType.RENTAL, BookingType.TEST_DRIVE].includes(props.bookingType as BookingType) && (
                    renderVehicleGroups()
                  )
                }

              </Grid>

            </Grid>
          </Grid>
          {showSummary() &&
            <>
              <Grid item xs={12} md={4} lg={3}>
                <SummaryPanel bookingInput={values} oneWayRentalFee={props.oneWayRentalFee ? props.oneWayRentalFee : undefined} />
              </Grid>
            </>
          }
        </Grid>
      }
      {vehicleImages.length > 0 && <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={styles.modalContent}>
          <Slider {...settings} initialSlide={selectedImageIndex as number}>
            {vehicleImages.map((image, index) => (
              <div key={index} className={styles.sliderImageWrapper}>
                <Image imageKey={image.url} />
              </div>
            ))}
          </Slider>
        </div>
      </Modal>}
    </div >
  );
};

export default VehicleSelection;
