import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { CircularProgress, TextField, Typography, useMediaQuery } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import withStyles from "@material-ui/core/styles/withStyles";
import { IInvoice, InvoiceStatus } from "../../../../reducers/bookings/types";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { StyledTableCell, StyledTableRow } from "../../../StyledTable";
import TableBody from "@material-ui/core/TableBody";
import { useLazyQuery } from "@apollo/client";
import { GET_INVOICES } from "../../../../graphql/invoices/getInvoices";
import { DateTime as d } from "luxon";
import { useHistory } from "react-router-dom";
import { DATE_TYPE, toCurrency } from "../../../utils";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../../utils/localized.syntex";

export const StyledInput = withStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "8px 10px 9px 0 !important",
      borderColor: "#e9e9e9",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0,0,0,0.1)",
    },
  },
})(TextField);

const Invoices = () => {
  const history = useHistory();
  const [filters, setFilters] = useState<{
    invoiceRef?: string;
    invoiceStatuses?: string[];
  }>({ invoiceRef: "", invoiceStatuses: [] });
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const website = useSelector(
    (state: IAppState) => state.consumerWebsiteReducer.consumerWebsite
  );
  const { currency, locale } = website.organisation;
  const { country } = website.organisation.address;

   const DTablet = useMediaQuery('(max-width: 768px)')

  const [getInvoices, { loading, data: invoicesData, error }] = useLazyQuery(
    GET_INVOICES,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    if (user) {
      getInvoices({
        variables: {
          businessCustomerId: user.businesscustomers?.id || undefined,
          customerId: user.customerId || undefined,
        },
      });
    }
  }, [user]);

  useEffect(() => {
    if (invoicesData && invoicesData.consumerInvoices) {
      setInvoices(invoicesData.consumerInvoices);
    }
  }, [invoicesData]);

  if (!invoices) {
    return <></>;
  }

  enum EmptyLabel {
    PAID = "No Paid Invoices Found!",
    UNPAID = "No Unpaid Invoices Found!",
    VOID = "No Void Invoices Found!",
    "PARTIALLY PAID" = "",
  }

  let filteredInvoices =
    filters && filters?.invoiceStatuses?.length
      ? invoices.filter(({ status }) =>
        filters.invoiceStatuses?.includes(status)
      )
      : invoices;
  filteredInvoices = filters.invoiceRef
    ? filteredInvoices.filter((invoice) =>
      invoice.invoiceRef
        .toLowerCase()
        .includes(filters.invoiceRef?.toLowerCase() || "")
    )
    : filteredInvoices;

  return (
    <div className={styles.column}>
      <div className={DTablet ? "flex fill col-flex": "flex fill cross-center"}>
        <div className="flex">
        <Typography variant="h2" className="semi-bold">
          Invoices
        </Typography>
        {loading && (
          <CircularProgress
            size={20}
            thickness={5}
            style={{ marginLeft: 10 }}
          />
        )}
        </div>
        <div className={styles.filters} style={{marginTop: DTablet ? '5px' : '0', marginLeft: DTablet ? '0': '5px'}}>
          <div>
            <StyledInput
              size="small"
              placeholder="Search Invoice Id"
              value={filters.invoiceRef}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, invoiceRef: e.target.value }))
              }
              InputProps={{
                style: {
                  padding: "0 0 0 8px",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ opacity: 0.4 }} fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <button
            className={`${styles.button} ${!filters.invoiceStatuses && styles.selected
              }`}
            onClick={() =>
              setFilters((prev) => ({ ...prev, invoiceStatuses: [] }))
            }
          >
            All
          </button>
          <button
            className={`${styles.button} ${styles.unpaid} ${(filters.invoiceStatuses?.includes(InvoiceStatus.UNPAID) &&
              styles.selected) ||
              (filters.invoiceStatuses?.includes(
                InvoiceStatus.PARTIALLY_PAID
              ) &&
                styles.selected)
              }`}
            onClick={() =>
              setFilters((prev) => ({
                ...prev,
                invoiceStatuses: [
                  InvoiceStatus.UNPAID,
                  InvoiceStatus.PARTIALLY_PAID,
                ],
              }))
            }
          >
            Unpaid
          </button>
          <button
            className={`${styles.button} ${styles.paid} ${filters.invoiceStatuses?.includes(InvoiceStatus.PAID) &&
              styles.selected
              }`}
            onClick={() =>
              setFilters((prev) => ({
                ...prev,
                invoiceStatuses: [InvoiceStatus.PAID],
              }))
            }
          >
            Paid
          </button>
          <button
            className={`${styles.button} ${filters.invoiceStatuses?.includes(InvoiceStatus.VOID) &&
              styles.selected
              }`}
            onClick={() =>
              setFilters((prev) => ({
                ...prev,
                invoiceStatuses: [InvoiceStatus.VOID],
              }))
            }
          >
            Void
          </button>
        </div>
      </div>
      {!loading && (
        <div className="margin-top padding-top">
          <TableContainer style={{ maxHeight: "100%" }} className="row-hover">
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Invoice Id</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>{`${getLocalizedBookingSyntex(country)} Ref`}</StyledTableCell>
                  <StyledTableCell>Invoice Date</StyledTableCell>
                  <StyledTableCell>Total</StyledTableCell>
                  <StyledTableCell>Balance Due</StyledTableCell>
                  <StyledTableCell>Due Date</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredInvoices.length ? (
                  filteredInvoices.map((invoice, index) => (
                    <StyledTableRow
                      key={index}
                      onClick={() =>
                        history.push(`/account/invoices/${invoice.id}`)
                      }
                    >
                      <StyledTableCell>{invoice.invoiceRef}</StyledTableCell>
                      <StyledTableCell>
                        <span
                          className={`${styles.chip} ${["PARTIALLY PAID", "UNPAID"].includes(
                            invoice.status
                          )
                            ? styles.unpaid
                            : styles.paid
                            }`}
                        >
                          {`${["PARTIALLY PAID", "UNPAID"].includes(
                            invoice.status
                          )
                            ? "UNPAID"
                            : invoice.status
                            }`}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell>
                        {invoice.booking ? invoice.booking.referenceNumber : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {getLocalizedDateFormat(country, invoice.dateCreated, DATE_TYPE.EXPANDED, invoice.booking?.branch?.timeZone)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {toCurrency(
                          invoice.totalPayableAmount,
                          currency,
                          locale
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {toCurrency(invoice.dueAmount, currency, locale)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {invoice.dueDate ? getLocalizedDateFormat(country, invoice.dueDate, DATE_TYPE.EXPANDED, invoice.booking?.branch?.timeZone) : "N/A"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableCell colSpan={20}>
                    <Typography style={{ textAlign: "center", opacity: 0.5 }}> "Sorry, no matching records found!"</Typography>
                  </StyledTableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default Invoices;
